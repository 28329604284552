import { render, staticRenderFns } from "./view-MT5.vue?vue&type=template&id=18f5eb54&scoped=true&"
import script from "./view-MT5.vue?vue&type=script&lang=js&"
export * from "./view-MT5.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18f5eb54",
  null
  
)

export default component.exports