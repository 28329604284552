<template>
    <div class="advantage">
        <div class="container">
            <h4 v-if="$i18n.locale == 'en'">{{$t('home.advantage.title')}}</h4>
            <img src="../../assets/image/home/advantage-title.png" alt="" srcset="" class="h4" v-else/>
            <ul class="advantage-list">
                <li class="advantage-item">
                    <img src="../../assets/image/home/advantage-icon-1.png" alt="" srcset="" />
                    <span>{{$t('home.advantage.service1')}}</span>
                    <small>{{$t('home.advantage.describe1')}}</small>
                    <!-- <button class="btn btn-primary" @click="$open('signUp', $i18n.locale)">{{$t('common.registerNow')}}</button> -->
                </li>
                <li class="advantage-item active">
                    <img src="../../assets/image/home/advantage-icon-2.png" alt="" srcset="" />
                    <span>{{$t('home.advantage.service2')}}</span>
                    <small>{{$t('home.advantage.describe2')}}</small>
                    <!-- <button class="btn btn-primary" @click="$open('signUp', $i18n.locale)">{{$t('common.registerNow')}}</button> -->
                </li>
                <li class="advantage-item">
                    <img src="../../assets/image/home/advantage-icon-3.png" alt="" srcset="" />
                    <span>{{$t('home.advantage.service3')}}</span>
                    <small>{{$t('home.advantage.describe3')}}</small>
                    <!-- <button class="btn btn-primary" @click="$open('signUp', $i18n.locale)">{{$t('common.registerNow')}}</button> -->
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'advantage-phone',

        data() {
            return {}
        },

        computed: {
            reasonList: () =>
                Array(4)
                    .fill('')
                    .map(() => ({
                        title: 'Global Operation',
                        content:
                            'Global Operations is a first-person tactical shooter video game developed ased in March 2002, following …',
                    })),
        },
    }
</script>

<style scoped lang="scss">
    .advantage {
        background: #F5F6F8;
        .container {
            width: 100vw;
            padding: 20px 15px;
            text-align: center;
            h4{
                width: 100%;
                height: 22.5px;
                font-size: 20px;
                font-family: Barlow-ExtraBold, Barlow;
                font-weight: 800;
                color: #000000;
                line-height: 22.5px;
            }
            .h4{
                width: auto;
                height: 22.5px;
                margin: 0 auto;
            }
        }
    }
    .advantage-list {
        margin-top: 18px;
        .advantage-item {
            position: relative;
            width: 100%;
            height: 74px;
            background-color: #fff;
            border-radius: 5px;
            margin-bottom: 14px;
            padding: 18px 12.5px;
            box-shadow: 0px 2.5px 15px 0px rgba(73,86,117,0.3000);
            img{
                width: 24px;
                height: 24px;
                float: left;
                margin-top: 8px;
                margin-right: 20px;
            }
            span{
                display: block;
                width: calc(100% - 44px);
                height: 18.5px;
                line-height: 18.5px;
                color: #000;
                font-weight: bolder;
                text-align: left;
            }
            small{
                display: block;
                float: left;
                width: calc(100% - 104px);
                min-height: 18.5px;
                line-height: 18.5px;
                color: rgba(0,0,0,0.6);
                font-weight: 400;
                text-align: left;
            }
            .btn{
                position: absolute;
                right: 10px;
                top: 20px;
                min-width: 75px;
                height: 30px;
                line-height: 30px;
            }
        }
        .active {
          background: #0d73d6;
          span,
          small {
            color: #fff;
          }
        }

    }
</style>
