<template>
  <div>
    <div class="banner box">
      <h4>{{$t("aboutUs.ourAdvantage.service1")}}</h4>
      <p>{{$t("aboutUs.ourAdvantage.describe1")}}</p>
      <line-chart></line-chart>
    </div>
    <div class="activity box">
      <h4>{{$t("aboutUs.ourAdvantage.service2")}}</h4>
      <div class="activity-pic"></div>
      <p>{{$t("aboutUs.ourAdvantage.describe2")}}</p>
      <button class="btn btn-primary" @click="$open('login', $i18n.locale)">{{$t("aboutUs.ourAdvantage.trading")}}</button>
    </div>

    <div class="training box">
        <h4>{{$t("aboutUs.ourAdvantage.service3")}}</h4>
        <p>{{$t("aboutUs.ourAdvantage.describe3")}}</p>
    </div>

    <div class="community box">
      <h4>{{$t("aboutUs.ourAdvantage.service4")}}</h4>
      <p>{{$t("aboutUs.ourAdvantage.describe4")}}</p>
      <button class="btn btn-primary" @click="$open('login', $i18n.locale)">{{$t("aboutUs.ourAdvantage.trading")}}</button>
    </div>
  </div>
</template>

<script>
import LineChart from '@/components/home/gpb-usd/gpb-chart';

export default {
  name: 'our-advantage',

  components: {
    LineChart
  }
}
</script>

<style scoped lang="scss">
  .box{
    width: 100vw;
    height: auto;
    padding: 20px 15px;
    text-align: center;
    h4{
      width: 100%;
      height: 22.5px;
      line-height: 22.5px;
      text-align: center;
      color: #000000;
      font-size: 20px;
      font-weight: 600;
    }
    p {
      width: 100%;
      height: auto;
      font-size: 13px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.8);
      line-height: 20px;
      margin-top: 10px;
    }
    .btn{
      height: 30px;
      line-height: 30px;
      margin-top: 20px;
    }
  }
  .activity{
    background: #F5F6F8;
    .activity-pic{
      width: 150px;
      height: 150px;
      background-size: cover;
      background-position: center center;
      @include bg-image('../../assets/image/about-us/advantage-chart');
      margin: 20px auto;
    }
  }
  .training{
    background-size: cover;
    background-position: center center;
    @include bg-image('../../assets/image/about-us/advantage-training');
    padding: 50px 10px;
    h4{
      color: #fff;
    }
    p{
      color: rgba(255,255,255,0.8);
    }
  }
  .community{
    background-size: cover;
    background-position: center center;
    @include bg-image('../../assets/image/about-us/advantage-community');
  }
</style>