<template>
  <div class="contact-us container">
    <div class="box">
<!--      <div class="form-left">-->
<!--        <img src="../../assets/image/logo/logo.png" alt="" srcset="" />-->
<!--        <p class="title">{{$t('home.contactus.customerServiceEmail')}}</p>-->
<!--        <p class="email">Support@ft.com</p>-->
<!--      </div>-->
<!--      <div class="form-right">-->
        <div class="title">{{$t('home.contactus.contactus')}}</div>
        <el-form :model="contactForm" :rules="rules" size="medium" ref="contactForm"
                 :label-width="screenWidth > 767 ? '140px' : '80px'"
                 :style="{width: screenWidth > 767 ? '90vw' :'80vw'}" class="demo-ruleForm">
          <el-form-item :label="$t('home.contactus.name')" prop="name">
            <el-input v-model="contactForm.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('home.contactus.email')" prop="email">
            <el-input v-model="contactForm.email"></el-input>
          </el-form-item>
          <el-form-item :label="$t('home.contactus.phone')" prop="phone">
            <el-input v-model="contactForm.phone"></el-input>
          </el-form-item>
          <el-form-item :label="$t('home.contactus.problem')" prop="desc">
            <el-input type="textarea" :rows="4" v-model="contactForm.desc" style="height: 100px;"></el-input>
          </el-form-item>
          <el-form-item label-width="0" style="text-align: center">
            <button class="btn btn-primary" @click="submitForm('contactForm')">{{$t('home.contactus.sendNow')}}</button>
          </el-form-item>
        </el-form>
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import { GbpService } from '@/service/gbp'
const gbpService = new GbpService()
export default {
  name: 'contact-us-phone',
  data() {
    return {
      contactForm: {
        name: '',
        email: '',
        phone: '',
        desc: ''
      }
    }
  },
  computed: {
    rules() {
      return {
        name: [{ required: true, message: this.$t('common.enterName'), trigger: 'blur' }],
        email: [{ required: true, message: this.$t('common.enterEmail'), trigger: 'blur' }],
        phone: [{ required: true, message: this.$t('common.enterPhone'), trigger: 'blur' }],
        desc: [{ required: false, message: this.$t('common.enterName'), trigger: 'blur' }]
      }
    },
    screenWidth() {
      let width = localStorage.getItem('screen_width') || 1200
      return width
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let time = new Date().getTime()
          let key = 'ft' + this.contactForm.phone.length + this.contactForm.phone + time
          let params = {
            name: this.contactForm.name,
            email: this.contactForm.email,
            phone: this.contactForm.phone,
            question: this.contactForm.desc,
            time: time,
            sign: this.$md5(key)
          }
          gbpService.sendQuestion(params).then(res => {
            if(res) {
              this.contactForm = {
                name: '',
                email: '',
                phone: '',
                desc: ''
              }
              alert(this.$t('common.sendSuccess'))
            } else {
              alert(this.$t('common.sendFail'))
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.contact-us {
  padding: 0 15px 30px 15px;
  margin-top: -42px;
  width: 100vw;
  .box {
    /*display: flex;*/
    border-radius: 10px;
    background: #FFFFFF;
    box-shadow: 0px 0px 30px 0px rgba(33, 66, 171, 0.35);
    padding-bottom: 10px;
    .title{
      width: 100%;
      height: 22.5px;
      font-size: 20px;
      font-family: Barlow-ExtraBold, Barlow;
      font-weight: 800;
      color: #000;
      line-height: 22.5px;
      text-align: center;
      padding: 20px;
      margin-bottom: 24px;
    }

      /deep/ .el-form-item {
        margin-bottom: 16px;
      }
      /deep/ .el-input--medium .el-input__inner,
      /deep/ .el-textarea__inner {
        background: #F3F5F7;
        border-radius: 2px;
      }
    .btn {
      width: 120px;
      height: 30px;
      line-height: 30px;
      margin: 0 auto;
    }
  }
}
</style>
