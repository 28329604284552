<template>
  <div class="contact-us container">
    <div class="box">
      <div class="form-left">
        <img src="../../assets/image/logo/logo.png" alt="" srcset="" />
        <p class="title">{{$t('home.contactus.customerServiceEmail')}}</p>
        <p class="email">support@ft66fx.com</p>
      </div>
      <div class="form-right">
        <div class="title">{{$t('home.contactus.contactus')}}</div>
        <el-form :model="contactForm" :rules="rules" size="medium" ref="contactForm"
                 :label-width="screenWidth > 1200 ? '140px' : '80px'"
                 :style="{width: screenWidth > 1200 ? '420px' :'350px'}" class="demo-ruleForm">
          <el-form-item :label="$t('home.contactus.name')" prop="name">
            <el-input v-model="contactForm.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('home.contactus.email')" prop="email">
            <el-input v-model="contactForm.email"></el-input>
          </el-form-item>
          <el-form-item :label="$t('home.contactus.phone')" prop="phone">
            <el-input v-model="contactForm.phone"></el-input>
          </el-form-item>
          <el-form-item :label="$t('home.contactus.problem')" prop="desc">
            <el-input type="textarea" :rows="4" v-model="contactForm.desc" style="height: 100px;"></el-input>
          </el-form-item>
          <el-form-item>
            <button class="btn btn-primary" @click="submitForm('contactForm')">{{$t('home.contactus.sendNow')}}</button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { GbpService } from '@/service/gbp'
const gbpService = new GbpService()
export default {
  name: 'contact-us',
  data() {
    return {
      contactForm: {
        name: '',
        email: '',
        phone: '',
        desc: ''
      }
    }
  },
  computed: {
    rules() {
      return {
        name: [{ required: true, message: this.$t('common.enterName'), trigger: 'blur' }],
        email: [{ required: true, message: this.$t('common.enterEmail'), trigger: 'blur' }],
        phone: [{ required: true, message: this.$t('common.enterPhone'), trigger: 'blur' }],
        desc: [{ required: false, message: this.$t('common.enterName'), trigger: 'blur' }]
      }
    },
    screenWidth() {
      let width = localStorage.getItem('screen_width') || 1200
      return width
    }
  },
  methods: {
     submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let time = new Date().getTime()
          let key = 'ft' + this.contactForm.phone.length + this.contactForm.phone + time
          let params = {
            name: this.contactForm.name,
            email: this.contactForm.email,
            phone: this.contactForm.phone,
            question: this.contactForm.desc,
            time: time,
            sign: this.$md5(key)
          }
          gbpService.sendQuestion(params).then(res => {
            if(res) {
              this.contactForm = {
                name: '',
                email: '',
                phone: '',
                desc: ''
              }
              alert(this.$t('common.sendSuccess'))
            } else {
              alert(this.$t('common.sendFail'))
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.contact-us {
  padding: 0 150px;
  margin-top: -52px;
  .box {
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px 0px rgba(33, 66, 171, 0.15);
    .form-left {
      width: 340px;
      height: 460px;
      padding-top: 118px;
      text-align: center;
      background-size: cover;
      @include bg-image('../../assets/image/home/contact-form-bg');
      img {
        width: 98px;
        height: 24px;
        margin-bottom: 81px;
      }
      .title {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.6);
        line-height: 20px;
        margin-bottom: 15px;
      }
      .email {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
      }
    }
    .form-right {
      width: 530px;
      padding-top: 46px;
      margin-left: 30px;
      .title {
        height: 38px;
        margin-bottom: 12px;
        font-size: 24px;
        font-family: AlibabaPuHuiTi-Bold, AlibabaPuHuiTi;
        font-weight: bold;
        color: #000000;
        line-height: 33px;
        text-align: center;
        ::after {
          content: 'Contact Us';
          color: rgba(0, 0, 0, .06);
        }
      }
      /deep/ .el-form-item {
        margin-bottom: 16px;
      }
      /deep/ .el-input--medium .el-input__inner,
      /deep/ .el-textarea__inner {
        background: #F3F5F7;
        border-radius: 2px;
      }
    }
    .btn {
      width: 120px;
      margin-top: 20px;
      margin-left: 80px;
    }
  }
}
</style>
