<template>
  <div class="chart-wrapper">
    <div class="chart-type">
      <!-- <span v-for="code in codes" :class="apiCode === code ? 'active' : ''" @click="apiCode = code">{{code}}</span> -->
      <span class="active">{{ apiCode }}</span>
    </div>
    <div class="chart" ref="chartRef"></div>
    <div class="chart-accuracy">
      <span
        v-for="accuracy in accuracys"
        :class="currQueryRange === accuracy ? 'active' : ''"
        @click="currQueryRange = accuracy"
        >{{ accuracy }}</span
      >
    </div>
    <div class="box">
      <el-carousel height="100px" direction="vertical" :autoplay="true">
        <el-carousel-item v-for="(item, idx) in Math.round(codeList.length / 2)" :key="idx">
          <div
            class="chart-hot"
            :class="codeList[idx * 2].type"
            @click="apiCode = codeList[idx * 2].code"
            v-if="idx < codeList.length / 2"
          >
            <div class="name">
              <span>{{ codeList[idx * 2].code }}</span>
              <span class="sub-name">({{ codeList[idx * 2].mold }})</span>
            </div>
            <div class="price">{{ codeList[idx * 2].price }}</div>
            <div class="price">{{ codeList[idx * 2].aprice }}</div>
            <div class="range">{{ codeList[idx * 2].pt }}</div>
            <div class="proportion">{{ codeList[idx * 2].percentage }}%</div>
          </div>
          <div
            class="chart-hot"
            :class="codeList[idx * 2 + 1].type"
            @click="apiCode = codeList[idx * 2 + 1].code"
            v-if="idx * 2 + 1 < codeList.length"
          >
            <div class="name">
              <span>{{ codeList[idx * 2 + 1].code }}</span>
              <span class="sub-name">({{ codeList[idx * 2 + 1].mold }})</span>
            </div>
            <div class="price">{{ codeList[idx * 2 + 1].price }}</div>
            <div class="price">{{ codeList[idx * 2 + 1].aprice }}</div>
            <div class="range">{{ codeList[idx * 2 + 1].pt }}</div>
            <div class="proportion">{{ codeList[idx * 2 + 1].percentage }}%</div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import _ from 'lodash'
import moment from 'moment'

import { color, getDefaultChartOption } from '@/utils/chart'
import { returnFloat, digits } from '@/utils/common'
import { nowOffsetTime, opening } from '@/utils/dateUtils'
import { GbpService } from '@/service/gbp'

const gbpService = new GbpService()

export default {
  name: 'gpb-chart',

  data() {
    return {
      codeList: [
        {
          code: 'EURUSD',
          mold: 'Forex',
          price: '-',
          aprice: '-',
          digit: 0,
          pt: '-',
          percentage: '-',
          type: null
        },
        {
          code: 'USDCAD',
          mold: 'Forex',
          price: '-',
          aprice: '-',
          digit: 0,
          pt: '-',
          percentage: '-',
          type: null
        },
        {
          code: 'GBPUSD',
          mold: 'Forex',
          price: '-',
          aprice: '-',
          digit: 0,
          pt: '-',
          percentage: '-',
          type: null
        },
        {
          code: 'USDJPY',
          mold: 'Forex',
          price: '-',
          aprice: '-',
          digit: 0,
          pt: '-',
          percentage: '-',
          type: null
        },
        {
          code: 'AUDUSD',
          mold: 'Forex',
          price: '-',
          aprice: '-',
          digit: 0,
          pt: '-',
          percentage: '-',
          type: null
        },
        {
          code: 'USDCHF',
          mold: 'Forex',
          price: '-',
          aprice: '-',
          digit: 0,
          pt: '-',
          percentage: '-',
          type: null
        },
        {
          code: 'NZDUSD',
          mold: 'Forex',
          price: '-',
          aprice: '-',
          digit: 0,
          pt: '-',
          percentage: '-',
          type: null
        },
        {
          code: 'XAUUSD',
          mold: 'Metals',
          price: '-',
          aprice: '-',
          digit: 0,
          pt: '-',
          percentage: '-',
          type: null
        },
        {
          code: 'XAGUSD',
          mold: 'Metals',
          price: '-',
          aprice: '-',
          digit: 0,
          pt: '-',
          percentage: '-',
          type: null
        },
        {
          code: 'USOIL',
          mold: 'Energy',
          price: '-',
          aprice: '-',
          digit: 0,
          pt: '-',
          percentage: '-',
          type: null
        },
        {
          code: 'UKOIL',
          mold: 'Energy',
          price: '-',
          aprice: '-',
          digit: 0,
          pt: '-',
          percentage: '-',
          type: null
        },
        {
          code: 'US30',
          mold: 'CFD',
          price: '-',
          aprice: '-',
          digit: 0,
          pt: '-',
          percentage: '-',
          type: null
        },
        {
          code: 'UK100',
          mold: 'CFD',
          price: '-',
          aprice: '-',
          digit: 0,
          pt: '-',
          percentage: '-',
          type: null
        },
        {
          code: 'HK50',
          mold: 'CFD',
          price: '-',
          aprice: '-',
          digit: 0,
          pt: '-',
          percentage: '-',
          type: null
        },
        {
          code: 'AU200',
          mold: 'CFD',
          price: '-',
          aprice: '-',
          digit: 0,
          pt: '-',
          percentage: '-',
          type: null
        },
        {
          code: 'JP225',
          mold: 'CFD',
          price: '-',
          aprice: '-',
          digit: 0,
          pt: '-',
          percentage: '-',
          type: null
        },
        {
          code: 'UT100',
          mold: 'CFD',
          price: '-',
          aprice: '-',
          digit: 0,
          pt: '-',
          percentage: '-',
          type: null
        },
        {
          code: 'CHINA50',
          mold: 'CFD',
          price: '-',
          aprice: '-',
          digit: 0,
          pt: '-',
          percentage: '-',
          type: null
        },
        {
          code: 'DE30',
          mold: 'CFD',
          price: '-',
          aprice: '-',
          digit: 0,
          pt: '-',
          percentage: '-',
          type: null
        },
        {
          code: 'BTCUSD',
          mold: 'Crypto',
          price: '-',
          aprice: '-',
          digit: 0,
          pt: '-',
          percentage: '-',
          type: null
        },
        {
          code: 'ETHUSD',
          mold: 'Crypto',
          price: '-',
          aprice: '-',
          digit: 0,
          pt: '-',
          percentage: '-',
          type: null
        }
      ],
      accuracys: ['M1', 'M5', 'H1', 'H4', 'D1', 'W1'],
      chartRef: 'chartRef',
      chart: null,
      option: null,
      chartType: 'kChart',
      dataSet: [],
      apiCode: 'EURUSD',
      currQueryRange: 'M1',
      isInput: false,
      dayStart: [],
      price: '0',
      sell: {
        main: 0,
        sup: 0
      },
      buy: {
        main: 0,
        sup: 0
      },
      textData: {
        data: '',
        percentage: { data: '', type: '' },
        pt: { data: '', type: '' }
      },
      chartData: {
        name: 'EURUSD',
        data: [],
        type: 'WEEK'
      },
      timer: 0,
      symbolTimer: 0,
      websock: null,
      queryInfoMapping: {
        M1: { type: 'DAY', period: 1, pastTimes: 60 * 80 * 4 },
        M5: { type: 'DAY', period: 5, pastTimes: 60 * 5 * 80 * 4 },
        M15: { type: 'DAY', period: 15, pastTimes: 60 * 15 * 80 * 4 },
        M30: { type: 'DAY', period: 30, pastTimes: 60 * 30 * 80 * 4 },
        H1: { type: 'WEEK', period: 60, pastTimes: 60 * 60 * 80 * 4 },
        H4: { type: 'WEEK', period: 240, pastTimes: 60 * 60 * 4 * 80 * 4 },
        D1: { type: 'WEEK', period: 1440, pastTimes: 60 * 60 * 24 * 80 * 4 },
        W1: { type: 'MONTH', period: 10080, pastTimes: 60 * 60 * 24 * 7 * 80 * 4 },
        MN: { type: 'MONTH', period: 43200, pastTimes: 60 * 60 * 24 * 30 * 80 * 4 }
      }
    }
  },

  computed: {},

  watch: {
    apiCode() {
      this.getChartData()
    },

    currQueryRange() {
      this.getChartData()
    }
  },

  methods: {
    initChart() {
      this.chart = echarts.init(this.$refs[this.chartRef])
      this.mergeBaseChartOption()
    },

    async getChartData() {
      clearTimeout(this.timer)
      let period = this.queryInfoMapping[this.currQueryRange].period
      let nowTime = nowOffsetTime(3)

      let params = {
        reqtype: 'historyratesinfo',
        reqid: '100',
        symbol: this.apiCode,
        period: period,
        fromtime: nowTime - this.queryInfoMapping[this.currQueryRange].pastTimes,
        endtime: nowTime
      }

      this.chartData.type = this.queryInfoMapping[this.currQueryRange].type
      this.chartData.data = await gbpService.getHistoryRatesInfo(params)
      let chartData = this.chartData.data

      this.xAxisData = chartData.map(d => new Date(d.time).getTime())
      this.dataSet = chartData.map(d => d.data)
      this.option.xAxis.data = this.xAxisData
      this.getPriceBySymbol()

      this.chart.setOption(this.option)
      opening() &&
        (this.timer = setTimeout(() => {
          this.getChartData()
        }, 30000))
    },

    async getPriceBySymbol() {
      clearTimeout(this.symbolTimer)
      let _this = this
      let params = {
        symbol_array: [this.apiCode]
      }
      await gbpService.getPriceBySymbol(params).then(data => {
        let digit = Math.pow(10, data[0].DIGITS)
        _this.dataSet.forEach(d => {
          d[4] = digit
        })
        _this.option.series = _this.getSeries()
        let sellText = returnFloat(data[0].BID, _this.apiCode)
        if (_this.option.series[0] && _this.option.series[0].markLine) {
          _this.option.series[0].markLine.data[0].yAxis = sellText
          _this.chart.setOption(_this.option)
        }
        _this.symbolTimer = setTimeout(() => {
          _this.getPriceBySymbol()
        }, 5000)
      })
    },

    mergeBaseChartOption() {
      let _this = this
      this.option = _.merge(getDefaultChartOption(), {
        grid: {
          left: 10,
          right: 0,
          button: 0,
          top: 26,
          containLabel: true
        },
        xAxis: {
          show: true,
          boundaryGap: true,
          axisTick: { show: false },
          axisLabel: {
            show: true,
            interval: 28,
            color: 'rgba(0, 0, 0, 0.4)',
            formatter: time => {
              return moment(new Date(Number(time))).format('HH:mm')
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.06)'
            }
          }
        },
        yAxis: {
          axisLine: { show: false },
          splitNumber: 2,
          offset: 16,
          min: v => {
            return v.min
          },
          axisLabel: {
            inside: true,
            fontSize: 12,
            color: 'rgba(0, 0, 0, 0.6)',
            verticalAlign: 'bottom',
            formatter: function(value, index) {
              return returnFloat(value, _this.chartData.name)
            }
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.06)'
            }
          }
        },
        tooltip: { trigger: 'axis' },
        axisPointer: {
          label: {
            formatter: ({ value }) => moment(+value).format('YYYY-MM-DD HH:mm:ss')
          }
        },
        color: [color.red, color.green],
        series: {}
      })
    },

    getSeries() {
      let _this = this
      const seriesCount = this.chartType === 'kChart' ? 1 : 4
      let dataset = [..._this.dataSet]
      dataset = Array(seriesCount)
        .fill('')
        .map((d, i) => _this.dataSet.map(d => {
          return d[i] / d[4]
        }))

      return Array(seriesCount)
        .fill('')
        .map((d, i) => ({
          type: 'line',
          data: dataset[i],
          symbol: 'circle',
          showSymbol: false,
          smooth: true,
          itemStyle:
            seriesCount === 4
              ? {
                  color: color.green,
                  color0: color.red,
                  borderColor: color.green,
                  borderColor0: color.red
                }
              : {
                  color: '#0D73D6'
                },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgba(13, 115, 214, 0.3)'
                },
                {
                  offset: 0.5,
                  color: 'rgba(13, 115, 214, 0.1)'
                },
                {
                  offset: 1,
                  color: 'transparent' // 100% 处的颜色
                }
              ]
            }
          },
          markLine: {
            symbol: 'none',
            precision: 5,
            label: {
              backgroundColor: color.red,
              color: '#fff',
              fontSize: 14,
              position: 'insideEndTop',
              padding: [6, 4, 0, 2],
              formatter: function(params) {
                return returnFloat(params.value, _this.chartData.name)
              }
            },
            lineStyle: {
              color: color.red
            },
            data: [{ yAxis: _this.price, name: '最新价' }]
          }
        }))
    },

    async getCodeData() {
      let nowTime = nowOffsetTime(3)
      let priceData = []
      let params = {
        symbol_array: this.codeList.map(item => item.code)
      }
      await gbpService.getPriceBySymbol(params).then(data => {
        priceData = data
      })

      this.codeList.forEach(async item => {
        let params = {
          reqtype: 'historyratesinfo',
          reqid: '100',
          symbol: item.code,
          period: 1440,
          fromtime: nowTime - 60 * 60 * 24,
          endtime: nowTime
        }

        let chartData = await gbpService.getHistoryRatesInfo(params)
        if (chartData[0] && chartData[0].data.length > 0) {
          let newData = chartData[chartData.length-1];
          let lastData = priceData.find(i => i.SYMBOL === item.code)
          let digit = Math.pow(10, lastData.DIGITS)
          item.digit = lastData.DIGITS
          item.price = lastData.BID
          item.aprice = lastData.ASK
          item.pt = returnFloat(newData.data[0] - newData.data[3], item.code) / digit
          item.percentage = ((newData.data[0] - newData.data[3]) / newData.data[3] * 100).toFixed(2)
          item.type = item.pt > 0 ? 'up' : 'down'
        }
      })
    }
  },

  mounted() {
    this.initChart()
    this.$nextTick(() => {
      this.getChartData()
      this.getCodeData()
    })
  }
}
</script>

<style scoped lang="scss">
.chart-wrapper {
  padding: 0 30px;
  .chart-type {
    padding-top: 24px;
    span {
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-, PingFang SC;
      font-weight: normal;
      color: #000000;
      line-height: 22px;
      cursor: pointer;
      margin-right: 16px;
      &.active {
        padding: 1px 14px;
        height: 24px;
        font-weight: 800;
        color: #ffffff;
        background: #0d73d6;
        border-radius: 2px;
      }
    }
  }
  .chart {
    height: 280px;
    width: 860px;
    background-color: var(--nav-bg);
    margin: 0 auto;
  }
  .chart-accuracy {
    padding-bottom: 16px;
    display: flex;
    span {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      line-height: 20px;
      padding-right: 30px;
      cursor: pointer;
      &.active {
        font-weight: 800;
        color: rgba(42, 91, 214, 1);
      }
    }
  }
  .box {
    padding: 0 30px;
    margin: 0 -30px;
    background: rgba(245, 246, 248, 0.6);
    .chart-hot {
      display: flex;
      padding: 13px 0;
      border-bottom: 1px solid #ebedf1;
      font-family: PingFangSC-Regular, PingFang SC;
      line-height: 22px;
      cursor: pointer;
      .name {
        height: 22px;
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        flex: 1;
        .sub-name {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.6);
          margin-left: 4px;
        }
      }
      .price {
        width: 120px;
        height: 22px;
        font-size: 16px;
        font-weight: normal;
        color: #000000;
        text-align: right;
      }
      .range {
        width: 92px;
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.3);
        text-align: right;
      }
      .proportion {
        width: 70px;
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.3);
        text-align: right;
      }
      &.up {
        .range,
        .proportion {
          color: #2b8674;
        }
      }
      &.down {
        .range,
        .proportion {
          color: #c02b35;
        }
      }
    }
  }
}

@media screen and (min-width: 992px) and(max-width: 1200px) {
  .chart{
    width: calc(100vw - 350px) !important;
  }
}

@media screen and (min-width: 768px) and(max-width: 992px) {
  .chart{
    width: calc(100vw - 30px) !important;
  }
  .chart-wrapper{
    padding: 0;
    .box{
      padding: 0;
      margin: 0;
    }
  }
}
@media screen and (max-width: 768px) {
  .chart{
    width: calc(100vw - 30px) !important;
  }
  .chart-wrapper{
    padding: 0;
    .box{
      padding: 0;
      margin: 0;
    }
  }
}
</style>
