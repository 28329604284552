<template>
  <div>
    <FundInfo v-if="screenWidth > 992"/>
    <fund-info-phone v-else/>
    <FundTable />
    <FundQuestion />
  </div>
</template>

<script>
import FundInfo from './fund-info'
import FundTable from './fund-table'
import FundQuestion from './fund-question'
import FundInfoPhone from "./fund-info-phone";
export default {
  components:{
    FundInfoPhone,
    FundInfo,
    FundTable,
    FundQuestion
  },
  data(){
    return {}
  },
  computed:{
    screenWidth() {
      let width = localStorage.getItem('screen_width') || 1200
      console.log(width)
      return width
    },
  }
}
</script>
<style scoped>
</style>