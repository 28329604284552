<template>
  <div class="register-process">
    <div class="w1200">
      <div class="top">
        <div class="des">{{$t("support.register.service")}}</div>
        <div class="more">More <img src="@/assets/image/support/right-arrow.png" ></div>
      </div>
      <div class="bottom">
        <div class="item">
          <img src="@/assets/image/support/01.png">
          <div class="flow">{{$t("support.register.tab1")}}</div>
          <div class="line-blue"></div>
        </div>
        <div class="item">
          <img src="@/assets/image/support/02.png">
          <div class="flow">{{$t("support.register.tab2")}}</div>
          <div class="line-blue"></div>
        </div>
        <div class="item">
          <img src="@/assets/image/support/03.png">
          <div class="flow">{{$t("support.register.tab3")}}</div>
          <div class="line-blue"></div>
        </div>
        <div class="item">
          <img src="@/assets/image/support/04.png">
          <div class="flow">{{$t("support.register.tab4")}}</div>
          <div class="line-blue"></div>
        </div>
        <div class="item">
          <img src="@/assets/image/support/05.png">
          <div class="flow">{{$t("support.register.tab5")}}</div>
          <div class="line-blue"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components:{},
  props:{},
  data(){
    return {
    }
  }
}
</script>
<style scoped lang="scss">
.register-process{
  height: 600px;
  background-size: 100% 100%;
  @include bg-image('../../assets/image/support/process-bg');
  .w1200{
    width: 1120px;
    margin: 0 auto;
    padding-top: 1px;
    .top{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 115px;
      .des{
        font-size: 36px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 50px;
      }
      .more{
        display: flex;
        align-items: center;
        cursor: pointer;
        img{
          margin-left: 7px;
        }
      }
    }
    .bottom{
      display: flex;
      margin-top: 100px;
      .item{
        flex: 1;
        .flow{
          font-size: 20px;
          font-weight: normal;
          color: #FFFFFF;
          line-height: 28px;
          margin-top: 49px;
          margin-bottom: 16px;
        }
        .line-blue{
          width: 64px;
          height: 8px;
          background: #0D73D6;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {

}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .register-process{
    .w1200{
      width: 95vw;

    }
  }

}

@media screen and (min-width: 768px) and (max-width: 992px) {

}

@media screen and (max-width: 768px) {

}
</style>