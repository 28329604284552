<template>
  <div class="view-faq">
    <div class="warpper">
      <h3>{{$t("support.faq.title")}}</h3>
      <h4>{{$t("support.faq.describe1")}}</h4>
      <img v-if="$i18n.locale == 'en'" src="@/assets/image/support/step1-en.png">
      <img v-else src="@/assets/image/support/step1.png">
      <h4>{{$t("support.faq.describe2")}}</h4>
      <img v-if="$i18n.locale == 'en'" src="@/assets/image/support/step2-en.png">
      <img v-else src="@/assets/image/support/step2.png">
      <img v-if="$i18n.locale == 'en'" src="@/assets/image/support/step3-en.png">
      <img v-else src="@/assets/image/support/step3.png">
      <h4>{{$t("support.faq.describe3")}}</h4>
      <img v-if="$i18n.locale == 'zh'" src="@/assets/image/support/step3-1.png">
      <h4>{{$t("support.faq.describe4")}}</h4>
      <img v-if="$i18n.locale == 'en'" src="@/assets/image/support/step4-en.png">
      <img v-else src="@/assets/image/support/step4.png">
      <h4>{{$t("support.faq.describe5")}}</h4>
      <img v-if="$i18n.locale == 'en'" src="@/assets/image/support/step5-en.png">
      <img v-else src="@/assets/image/support/step5.png">
      <h4>{{$t("support.faq.describe6")}}</h4>
      <h4>{{$t("support.faq.describe61")}}</h4>
      <img v-if="$i18n.locale == 'en'" src="@/assets/image/support/step6-en.png">
      <img v-else src="@/assets/image/support/step6.png">
      <div class="btn-box"><button class="btn btn-primary" @click="$open('signUp', $i18n.locale)">{{$t("common.registerNow")}}</button></div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
    }
  }
}
</script>
<style scoped lang="scss">
.view-faq{
  padding-top: 80px;
  .warpper{
    width: 720px;
    margin: 0 auto;
    h3{
      font-size: 24px;
      font-weight: bold;
      font-weight: 500;
      color: #333333;
      line-height: 33px;
      margin-bottom: 20px;
    }
    h4{
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
      margin: 20px 0;
    }
    img{
      width: 100%;
      margin-bottom: 20px;
    }
    .btn-box{
      margin: 40px 0 60px;
      text-align: center;
    }
  }
}
@media screen and (max-width: 992px) {
  .view-faq{
    padding-top: 20px;
    .warpper{
      width: 100vw;
      padding: 0 15px;
      h3{
        width: 100%;
        height: 22.5px;
        line-height: 22.5px;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        margin-top: 20px;
      }
      img{
        width: 100%;
      }
      .btn-box{
        height: 30px;
        line-height: 30px;
        margin: 10px 0 30px;
      }
    }
  }
}
</style>