<template>
  <div class="warpper">
    <DeviceMT5 />
    <FlexibleMT5 />
    <TimeMT5 />
    <DeepMT5 />
  </div>
</template>

<script>
import DeviceMT5 from './device-MT5'
import TimeMT5 from './time-MT5'
import FlexibleMT5 from './flexible-MT5'
import DeepMT5 from './deep-MT5'
export default {
  name: 'Forex',
  components: {
    DeviceMT5,
    TimeMT5,
    FlexibleMT5,
    DeepMT5
  },
  data() {
    return {}
  }
}
</script>

<style scoped lang="scss"></style>