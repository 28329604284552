<template>
    <div class="box">
        <homer-slider />
        <gpb-usd-phone/>
        <advantage-phone/>
        <footer-slider-phone/>
        <start-slider-phone/>
        <contact-us-phone/>
    </div>
</template>

<script>

    import HomerSlider from '@/components/home/sliderPhone'
    import GpbUsdPhone from "../components/home/gpb-usd/gpd-usd-phone";
    import AdvantagePhone from "../components/home/advantage-phone";
    import FooterSliderPhone from "../components/home/footer-slider-phone";
    import StartSliderPhone from "../components/home/start-trading-slider-phone";
    import ContactUsPhone from "../components/home/contact-us-phone";
  export default {
    name: 'HomePhone',
    components: {
        ContactUsPhone,
        StartSliderPhone,
        FooterSliderPhone,
        AdvantagePhone,
        GpbUsdPhone,
        HomerSlider
    },
    data() {
      return {

      }
    },
    computed: {

    },
    methods: {

    }
  }
</script>

<style scoped lang="scss">
    .box{
        width: 100%;
        height: auto;
        padding-top: 44px;
        color: #000;
    }
</style>
