<template>
  <div id="app" :class="{'app-phone': screenWidth <= 992}" v-if="load">
    <router-view />
    <home-footer v-if="screenWidth > 992"></home-footer>
<!--    <home-footer-phone v-else></home-footer-phone>-->
  </div>
</template>

<script>
import HomeHeader from '@/components/header/header'
import HomeFooter from '@/components/footer/footer';
import HomeFooterPhone from './components/footer/footerPhone'

export default {
  components: {
    HomeFooterPhone,
    HomeFooter,
    HomeHeader,
  },
  data() {
    return {
      screenWidth: 0,
      load: false
    }
  },
  watch: {
    '$route': {
      handler(v) {
        if(window.screen.width >= 992) {
          document.documentElement.scrollTop = document.body.scrollTop = 0;
        }
      },
      deep: true
    }
  },
  created() {
    let that = this;
    window.onload=function(){
      let width = window.screen.width;
      that.screenWidth = width
      localStorage.setItem('screen_width', width);
      that.load = true
    }

  }
}
</script>
<style lang="scss">
#app {
  background: var(--theme-bg);
}
  .app-phone{
    overflow: hidden;
  }
</style>
