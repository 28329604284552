<template>
  <div class="warpper">
    <div class="item-product" v-for="(item, index) in otherProduct.filter(x => x.path != source)" :key="index">
      <div class="top">
        <div class="title">{{item.name}}</div>
        <img @click="goTo(item.path)" src="@/assets/image/deal/arrow.png">
      </div>
      <p>{{item.info}}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    source: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    otherProduct() {
      return [
        {
          name: this.$t("deal.title4"),
          info: this.$t("deal.describe4"),
          path: '/deal/forex'
        }, {
          name: this.$t("deal.title1"),
          info: this.$t("deal.describe1"),
          path: '/deal/metal'
        }, {
          name: this.$t("deal.title2"),
          info: this.$t("deal.describe2"),
          path: '/deal/energy'
        }, {
          name: this.$t("deal.title3"),
          info: this.$t("deal.describe3"),
          path: '/deal/exponent'
        }, {
          name: this.$t("deal.title5"),
          info: this.$t("deal.describe5"),
          path: '/deal/DigitalCurrency'
        }
      ]
    }
  },
  methods: {
    goTo(path) {
      this.$router.push({ path })
    }
  }
}
</script>
<style lang="scss" scoped>
.warpper{
  width: 1120px;
  margin: 0 auto;
  display: flex;
  .item-product{
    box-shadow: 0px 5px 30px 0px rgba(73,86,117,0.1000);
    border-radius: 2px;
    padding: 32px 32px 40px;
    flex: 1;
    margin-right: 20px;
    &:last-child{
      margin-right: 0;
    }
    .top{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title{
        font-size: 20px;
        color: #000000;
        font-weight: bold;
      }
    }
    p{
      font-size: 14px;
      font-weight: 400;
      color: rgba($color: #000000, $alpha: 0.6);
      margin-top: 20px;
      line-height: 20px;
    }
    &:hover{
      background: #0D73D6;
      .top .title{
        color: #fff;
      }
      img{
        content: url("../../assets/image/deal/arrow-hover.png");
      }
      p{
        color: rgba($color: #fff, $alpha: 0.6);
      }
    }
    img{
      cursor: pointer;
    }
  }
}


@media screen and (min-width: 1200px) {

}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .warpper{
    width: 95vw;
  }
}

@media screen and (max-width: 992px) {
  .warpper{
    width: 95vw;
    display: block;
    .item-product{
      flex: none;
      width: 100%;
      margin-bottom: 10px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}

</style>