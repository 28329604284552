import { get, post } from './request'
import { KEY as key } from './config'

export function getGbpChart(query) {
  const { count, apiCode, type } = query

  const urlSuffix = `${apiCode}/${type}/${count}`
  return get(`/instruments/prices/${urlSuffix}`, {
    key
  })
}

export function getPriceBySymbol(query) {
  const baseURL = process.env.NODE_ENV === 'production' ? '' : '/price'
  return post(baseURL + `/kl/getPriceBySymbol`, query)
}

export function leadsSave(query) {
  const baseURL = process.env.NODE_ENV === 'production' ? '' : '/newapi/kline'
  return post(baseURL + `/leads/save`, query)
}

export function getHistoryRatesInfo(query) {
  const baseURL = process.env.NODE_ENV === 'production' ? '' : '/history'
  return post(baseURL + `/historyratesinfo`, query)
}

export function getArticle(page, lang) {
  return get(`/api/post?page=` + page + `&size=12&lan=` + lang)
}

export function getArticleDetail(id, lang) {
  return get(`/api/post/detail?id=` + id + `&size=1&lan=` + lang)
}

export function sendQuestion(params) {
  return post(`/api/post/sendQuestion`, params)
}