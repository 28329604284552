<template>
  <div>
    <AgentInvite />
    <RegisterProcess v-if="screenWidth > 992"/>
    <register-process-phone v-else/>
  </div>
</template>

<script>
import AgentInvite from './agent-invite'
import RegisterProcess from './register-process'
import RegisterProcessPhone from "./register-process-phone";
export default {
  components:{
    RegisterProcessPhone,
    AgentInvite,
    RegisterProcess
  },
  props:{},
  data(){
    return {
    }
  },
  watch:{},
  computed:{
    screenWidth() {
      let width = localStorage.getItem('screen_width') || 1200
      console.log(width)
      return width
    },
  },
  methods:{},
  created(){},
  mounted(){}
}
</script>
<style scoped>
</style>