<template>
    <div class="box">
        <div class="service">
            <div class="service_left">
                <h4>{{$t("community.followingUp.title")}}</h4>
                <p>{{$t("community.followingUp.service")}}</p>
                <button class="btn btn-primary" @click="$open('signUp', $i18n.locale)">{{$t("common.registerNow")}}</button>
                <button class="btn btn-primary" style="margin-left: 12px;" @click="$open('signUp5', $i18n.locale)">{{$t("common.registerNow5")}}</button>
            </div>
            <div class="service_right">
                <img src="@/assets/image/community/following-up-en.png" v-if="$i18n.locale == 'en'">
                <img src="@/assets/image/community/following-up.png" v-else>
            </div>
        </div>
        <div class="tab">
            <ul>
                <li>
                    <p>
                        <img src="@/assets/image/community/following-up-tab-1.png">
                    </p>
                    <span v-html="$t('community.followingUp.tab1')"></span>
                </li>
                <li>
                    <p>
                        <img src="@/assets/image/community/following-up-tab-2.png">
                    </p>
                    <span v-html="$t('community.followingUp.tab2')"></span>
                </li>
                <li>
                    <p>
                        <img src="@/assets/image/community/following-up-tab-3.png">
                    </p>
                    <span v-html="$t('community.followingUp.tab3')"></span>
                </li>
                <li>
                    <p>
                        <img src="@/assets/image/community/following-up-tab-4.png">
                    </p>
                    <span v-html="$t('community.followingUp.tab4')"></span>
                </li>
                <li>
                    <p>
                        <img src="@/assets/image/community/following-up-tab-5.png">
                    </p>
                    <span v-html="$t('community.followingUp.tab5')"></span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'view-following-up'
  }
</script>

<style scoped lang="scss">
    .box{
        height: auto;
        overflow: hidden;
        padding: 78px 0 98px 0;
        .service{
            width: 1150px;
            height: auto;
            margin: 0 auto;
            overflow: hidden;
            &_left{
                width: 560px;
                height: auto;
                overflow: hidden;
                float: left;
                h4{
                    width: 100%;
                    min-height: 65px;
                    line-height: 65px;
                    font-size: 48px;
                    font-width: 600;
                    color: #000000;
                    margin-top: 92px;
                    white-space: pre;
                }
                p{
                    width: 100%;
                    height: auto;
                    font-size: 16px;
                    font-family: AlibabaPuHuiTiR;
                    color: rgba(0, 0, 0, 0.6);
                    line-height: 28px;
                    margin-top: 14px;
                    padding-right: 20px;
                    white-space: pre;
                }
                .btn{
                    margin-top: 36px;
                    border-radius: 20px;
                }
            }
            &_right{
                width: 583px;
                height: 487px;
                float: right;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit:contain ;
                }
            }
        }
        .tab{
            width: 1150px;
            height: auto;
            margin: 0 auto;
            overflow: hidden;

            ul{
                width: 100%;
                display: flex;
                li{
                    flex: 1;
                    text-align: center;
                    p{
                        width: 180px;
                        height: 180px;
                        background: #FFFFFF;
                        box-shadow: 0px 0px 20px 0px rgba(33,66,171,0.1500);
                        border-radius: 20px;
                        margin: 45px auto 26px;
                        img{
                            width: 56px;
                            height: 56px;
                            margin-top: 62px;
                        }
                    }
                    span{
                        font-size: 16px;
                        font-weight: 400;
                        color:rgba(0, 0, 0, 0.6);
                        line-height: 24px;
                        white-space: pre;
                    }
                }
            }
        }
    }


    @media screen and (min-width: 1200px) {

    }

    @media screen and (min-width: 992px) and (max-width: 1200px) {
        .box{
            .service{
                width: 95vw;
                &_left {
                    width: 400px;
                }
                &_right{
                    width: 480px;
                }
            }
            .tab{
                width: 95vw;
                ul li{
                    flex: 0.8;
                }
            }
        }

    }

    @media screen and (min-width: 768px) and (max-width: 992px) {

    }

    @media screen and (max-width: 768px) {

    }

</style>