import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import AboutUs from '@/views/AboutUs'
import WhoAreWe from '@/components/about-us/view-who'
import Contact from '@/components/about-us/view-contact'
import OurAdvantage from '@/components/about-us/view-advantage'

// 交易模块
import dealRouter from './deal'
// 社区模块
// import platformRouter from './platform'

// 平台模块
import communityRouter from './community'
// 支持模块
// import supportRouter from './support'

import phone from '../views/phone.vue'
import HomePhone from '../views/HomePhone.vue'
import OurAdvantagePhone from '@/components/about-us/view-advantage-phone'

import Platform from '@/views/Platform' // layout
import MT4 from '@/components/platform/view-MT4' // MT4
import MT5 from '@/components/platform/view-MT5' // MT5
import MT4Phone from '@/components/platform/view-MT4-phone' // MT4
import MT5Phone from '@/components/platform/view-MT5-phone' // MT5

import Deal from '@/views/Deal' // layout
import Dealforex from '@/components/deal/view-forex' // 外汇
import Dealmetal from '@/components/deal/view-metal' // 贵金属
import Dealenergy from '@/components/deal/view-energy' // 能源
import Dealexponent from '@/components/deal/view-exponent' // 指数
import DealLine from '@/components/deal/view-line' // K线
import DealDigitalCurrency from '@/components/deal/view-digital-currency'//数字货币

import Community from '@/views/community' // layout
import CommunityfollowingUp from '@/components/community/view-following-up' // 外汇
import CommunityfollowingUpPhone from '@/components/community/view-following-up-phone' // 外汇
import CommunitymultiAccountManagement from '@/components/community/view-multi-account-management' // 贵金属

import Support from '@/views/Support' // layout
import Agent from '@/components/support/view-agent' // 全民经纪人
import Fund from '@/components/support/view-fund' // 出金入金
import Faq from '@/components/support/view-faq' // 问题答疑



// 解决重复点击路由会在控制台报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

let routes = []
const screen_width = window.screen.width
if(screen_width > 992) {
  routes = [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/about-us',
      name: 'eal',
      component: AboutUs,
      redirect: '/about-us/who',
      children: [
        {
          name: 'who',
          path: '/about-us/who',
          component: WhoAreWe,
        },
        {
          name: 'contact',
          path: '/about-us/contact',
          component: Contact,
        },
        {
          name: 'advantage',
          path: '/about-us/advantage',
          component: OurAdvantage,

        }
      ]
    },
    // 交易模块
    {
      path: '/deal',
      name: 'Deal',
      component: Deal,
      redirect: '/deal/forex',
      children: [
        {
          name: 'forex',
          path: '/deal/forex',
          component: Dealforex
        },
        {
          name: 'metal',
          path: '/deal/metal',
          component: Dealmetal
        },
        {
          name: 'energy',
          path: '/deal/energy',
          component: Dealenergy
        },
        {
          name: 'exponent',
          path: '/deal/exponent',
          component: Dealexponent
        },
        {
          name: 'kLine',
          path: '/deal/kLine',
          component: DealLine
        },
        {
          name: 'DigitalCurrency',
          path: '/deal/DigitalCurrency',
          component: DealDigitalCurrency
        },
      ]
    },
    // 社区模块
    {
      path: '/community',
      name: 'Community',
      component: Community,
      redirect: '/community/followingUp',
      children: [
        {
          name: 'followingUp',
          path: '/community/followingUp',
          component: CommunityfollowingUp
        },
        {
          name: 'multiAccountManagement',
          path: '/community/multiAccountManagement',
          component: CommunitymultiAccountManagement
        },
      ]
    },
    // 平台模块
    // platformRouter,
    {
      path: '/platform',
      name: 'Platform',
      component: Platform,
      redirect: '/platform/MT4',
      children: [
        {
          name: 'MT4',
          path: '/platform/MT4',
          component: MT4
        },
        {
          name: 'MT5',
          path: '/platform/MT5',
          component: MT5
        }
      ]
    },
    {
      path: '/support',
      name: 'Support',
      component: Support,
      redirect: '/support/agent',
      children: [
        {
          name: 'Agent',
          path: '/support/agent',
          component: Agent
        },
        {
          name: 'Fund',
          path: '/support/fund',
          component: Fund
        },
        {
          name: 'Faq',
          path: '/support/faq',
          component: Faq
        }
      ]
    },
    {
      path: '/notice',
      name: 'Notice',
      component: () => import(/* webpackChunkName: "notice" */ '../views/Notice.vue'),
    },
    {
      path: '/noticeDetail',
      name: 'Detail',
      component: () => import(/* webpackChunkName: "notice" */ '../views/NoticeDetail.vue')
    },
    // 活动模块
    {
      path: '/activity',
      name: 'Activity',
      component: () => import(/* webpackChunkName: "activity" */ '../views/Activity.vue'),
    },
    {
      path: '/about',
      name: 'About',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    },
  ]
} else {
  routes = [
    {
      path: '/',
      name: 'phone',
      component: phone,
      redirect: '/phone/HomePhone',
      children: [
        {
          path: '/phone/HomePhone',
          name: 'HomePhone',
          component: HomePhone,
        },{
          path: '/phone/about-us',
          name: 'eal',
          component: AboutUs,
          redirect: '/phone/about-us/who',
          children: [
            {
              name: 'who',
              path: '/phone/about-us/who',
              component: WhoAreWe,
            },
            {
              name: 'contact',
              path: '/phone/about-us/contact',
              component: Contact,
            },
            {
              name: 'advantage',
              path: '/phone/about-us/advantage',
              component: OurAdvantagePhone,
            }
          ]
        },{
          path: '/phone/platform',
          name: 'Platform',
          component: Platform,
          redirect: '/phone/platform/MT4',
          children: [
            {
              name: 'MT4',
              path: '/phone/platform/MT4',
              component: MT4Phone
            },
            {
              name: 'MT5',
              path: '/phone/platform/MT5',
              component: MT5Phone
            }
          ]
        },
        {
          path: '/phone/deal',
          name: 'Deal',
          component: Deal,
          redirect: '/phone/deal/forex',
          children: [
            {
              name: 'forex',
              path: '/phone/deal/forex',
              component: Dealforex
            },
            {
              name: 'metal',
              path: '/phone/deal/metal',
              component: Dealmetal
            },
            {
              name: 'energy',
              path: '/phone/deal/energy',
              component: Dealenergy
            },
            {
              name: 'exponent',
              path: '/phone/deal/exponent',
              component: Dealexponent
            },
            {
              name: 'kLine',
              path: '/phone/deal/kLine',
              component: DealLine
            },
            {
              name: 'DigitalCurrency',
              path: '/phone/deal/DigitalCurrency',
              component: DealDigitalCurrency
            }
          ]
        },
        {
          path: '/phone/community',
          name: 'Community',
          component: Community,
          redirect: '/phone/community/followingUp',
          children: [
            {
              name: 'followingUp',
              path: '/phone/community/followingUp',
              component: CommunityfollowingUpPhone
            },
            {
              name: 'multiAccountManagement',
              path: '/phone/community/multiAccountManagement',
              component: CommunitymultiAccountManagement
            },
          ]
        },
        {
          path: '/phone/activity',
          name: 'Activity',
          component: () => import(/* webpackChunkName: "activity" */ '../views/Activity.vue'),
        },
        {
          path: '/phone/notice',
          name: 'Notice',
          component: () => import(/* webpackChunkName: "notice" */ '../views/Notice.vue'),
        },
        {
          path: '/phone/support',
          name: 'Support',
          component: Support,
          redirect: '/phone/support/agent',
          children: [
            {
              name: 'Agent',
              path: '/phone/support/agent',
              component: Agent
            },
            {
              name: 'Fund',
              path: '/phone/support/fund',
              component: Fund
            },
            {
              name: 'Faq',
              path: '/phone/support/faq',
              component: Faq
            }
          ]
        }
      ]
    },
    // 活动模块
    {
      path: '/about',
      name: 'About',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    },
  ]
}

const router = new VueRouter({
  routes,
})

export default router
