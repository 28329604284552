<template>
  <div class="warpper">
    <el-table :data="source" stripe>
      <el-table-column
        prop="symbols"
        :label="$t('deal.columns[0]')">
      </el-table-column>
      <el-table-column
        prop="contract"
        :label="$t('deal.columns[1]')">
      </el-table-column>
      <el-table-column
        prop="digits"
        :label="$t('deal.columns[2]')">
      </el-table-column>
      <el-table-column
        prop="minimum"
        :label="$t('deal.columns[3]')">
      </el-table-column>
      <el-table-column
        prop="maximun"
        :label="$t('deal.columns[4]')">
      </el-table-column>
      <el-table-column
        prop="step"
        :label="$t('deal.columns[5]')">
      </el-table-column>
      <el-table-column
        prop="leverage"
        align="center"
        :label="$t('deal.columns[6]')">
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  props: {
    source: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      otherHotForex: [{
        name: 'EURUSD欧元兑美元',
        price1: '最高500',
        price2: '120欧元',
        contract: '10万单位基本货币',
        point: '10美元',
        platformTime: '00:01~23:59 周五00:01~23:55',
        bjTime: '06:01~次日05:59 周五06:01~05:55'
      }, {
        name: 'EURUSD欧元兑美元',
        price1: '最高500',
        price2: '120欧元',
        contract: '10万单位基本货币',
        point: '10美元',
        platformTime: '00:01~23:59 周五00:01~23:55',
        bjTime: '06:01~次日05:59 周五06:01~05:55'
      }, {
        name: 'EURUSD欧元兑美元',
        price1: '最高500',
        price2: '120欧元',
        contract: '10万单位基本货币',
        point: '10美元',
        platformTime: '00:01~23:59 周五00:01~23:55',
        bjTime: '06:01~次日05:59 周五06:01~05:55'
      }, {
        name: 'EURUSD欧元兑美元',
        price1: '最高500',
        price2: '120欧元',
        contract: '10万单位基本货币',
        point: '10美元',
        platformTime: '00:01~23:59 周五00:01~23:55',
        bjTime: '06:01~次日05:59 周五06:01~05:55'
      }, {
        name: 'EURUSD欧元兑美元',
        price1: '最高500',
        price2: '120欧元',
        contract: '10万单位基本货币',
        point: '10美元',
        platformTime: '00:01~23:59 周五00:01~23:55',
        bjTime: '06:01~次日05:59 周五06:01~05:55'
      }, {
        name: 'EURUSD欧元兑美元',
        price1: '最高500',
        price2: '120欧元',
        contract: '10万单位基本货币',
        point: '10美元',
        platformTime: '00:01~23:59 周五00:01~23:55',
        bjTime: '06:01~次日05:59 周五06:01~05:55'
      }]
    }
  }
}
</script>
<style lang="scss" scoped>
.warpper{
  width: 1120px;
  margin: 0 auto;
  box-shadow: 0px 0px 20px 0px rgba(33,66,171,0.1500);
  border-radius: 2px;
}
/deep/ .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}
/deep/ .el-table--mini .el-table__cell {
    padding: 14px 0;
}

@media screen and (min-width: 1200px) {

}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .warpper{
    width: 95vw;
  }
}

@media screen and (max-width: 992px) {
  .warpper{
    width: calc(100vw - 30px);
  }
}
</style>