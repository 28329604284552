<template>
  <div class="home-footer">
    <footer-nav />
    <disclaimer />
  </div>
</template>

<script>
import FooterNav from './nav'
import Disclaimer from './disclaimer'
export default {
  name: 'home-footer',

  components: {
    FooterNav,
    Disclaimer,
  }
}
</script>

<style scoped lang="scss">
.home-footer {
  height: 680px;
  @include bg-image('../../assets/image/home/home-footer-bg');
}
</style>