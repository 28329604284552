<template>
  <div class="start-trading-slider">
    <div class="container">
        <h4>{{$t('home.startSlider.title3')}}</h4>
      <ul class="step-list">
        <li class="step-item">
          <img src="../../assets/image/home/step-01.png" alt="" srcset="" />
          <span>{{$t('home.startSlider.sliderTitle1')}}</span>
        </li>
        <li class="step-item">
          <img src="../../assets/image/home/step-02.png" alt="" srcset="" />
          <span>{{$t('home.startSlider.sliderTitle2')}}</span>
        </li>
        <li class="step-item">
          <img src="../../assets/image/home/step-03.png" alt="" srcset="" />
          <span>{{$t('home.startSlider.sliderTitle3')}}</span>
        </li>
        <li class="step-item">
          <img src="../../assets/image/home/step-04.png" alt="" srcset="" />
          <span>{{$t('home.startSlider.sliderTitle4')}}</span>
        </li>
        <li class="step-item">
          <img src="../../assets/image/home/step-05.png" alt="" srcset="" />
          <span>{{$t('home.startSlider.sliderTitle5')}}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'start-slider-phone',
}
</script>

<style scoped lang="scss">
.start-trading-slider {
  //background: var(--start-trading-slider-bg);
  /*height: 640px;*/
  height: auto;
  overflow: hidden;
  padding-top: 30px;
  @include bg-image('../../assets/image/home/start-trading-bg');
  .container {
    width: 100vw;
    h4{
      width: 100%;
      height: 22.5px;
      font-size: 20px;
      font-family: Barlow-ExtraBold, Barlow;
      font-weight: 800;
      color: #ffffff;
      line-height: 22.5px;
      text-align: center;
    }
    .step-list {
      margin-top: 28px;
      height: auto;
      overflow: auto;
      margin-bottom: 34px;
      padding-left: 30px;
      /*display: flex;*/
      /*justify-content: space-between;*/
      .step-item {
        float: left;
        width: 33.33%;
        display: flex;
        flex-direction: column;
        img {
          width: 36.5px;
          margin-bottom: 17px;
        }
        span {
          font-size: 14px;
          position: relative;
          margin-bottom: 50px;
          color: #fff;
          &:after {
            content: '';
            display: inline-block;
            width: 28.5px;
            height: 4px;
            background: #0D73D6;
            position: absolute;
            left: 0;
            bottom: -20px;
          }
        }
      }
    }
  }
  .btn {
    width: 120px;
  }
}
</style>
