<template>
<div>
  <header-slider />
  <div class="sub-page">
    <router-view></router-view>
  </div>
</div>
</template>

<script>
import HeaderSlider from '@/components/deal/slider';
export default {
  name: 'Deal',
  components: {
    HeaderSlider,
  }
}
</script>

<style scoped lang="scss">
.sub-page {
  /deep/ .el-breadcrumb__item {
    font-size: 16px;
  }
  /deep/ .el-breadcrumb__item:last-child .el-breadcrumb__inner {
    color: #000;
  }
}
</style>