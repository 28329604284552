<template>
  <div class="container footer-slider">
    <h3>{{$t('home.tradingPlatform.tradingPlatform')}}</h3>
    <el-carousel :interval="500000" arrow="never" height="380px">
      <el-carousel-item key="1">
        <div class="right"></div>
        <h4>{{$t('home.tradingPlatform.service')}} </h4>
        <p>{{$t('home.tradingPlatform.describe')}}</p>
        <div class="box">
          <button class="btn btn-primary" @click="$open('pc')">{{$t('home.tradingPlatform.pc')}}</button>
          <button class="btn btn-primary" @click="$open('mac')">{{$t('home.tradingPlatform.MAC')}}</button>
          <button class="btn" @click="$open('android')" style="margin-right: 5px">{{$t('home.tradingPlatform.android')}}</button>
          <button class="btn" @click="$open('iOS')">{{$t('home.tradingPlatform.ios')}}</button>
        </div>
      </el-carousel-item>
      <el-carousel-item key="2">
        <div class="right right5"></div>
        <h4>{{$t('home.tradingPlatform.service5')}}</h4>
        <p>{{$t('home.tradingPlatform.describe')}}</p>
        <div class="box">
          <button class="btn btn-primary" @click="$open('login5')">{{$t('home.tradingPlatform.pc')}}</button>
          <button class="btn btn-primary" @click="$open('login5')">{{$t('home.tradingPlatform.MAC')}}</button>
          <button class="btn" @click="$open('login5')" style="margin-right: 5px">{{$t('home.tradingPlatform.android')}}</button>
          <button class="btn" @click="$open('login5')">{{$t('home.tradingPlatform.ios')}}</button>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: 'footer-slider-phone',
}
</script>

<style scoped lang="scss">
  .container{
    width: 100vw;
    padding: 15px 0 0 0;
  }
.footer-slider {
  h3 {
    margin-bottom: 18px;
    color: #000;
    font-size: 20px;
    text-align: center;
    margin-top: 12px;
  }
  .el-carousel-item{
    width: 100%;
  }
  .right{
    width: 233.5px;
    height: 139px;
    margin: 0 auto;
    @include bg-image('../../assets/image/home/footer-slider');
    background-size: cover;
  }
  .right5 {
    @include bg-image('../../assets/image/platform/device-MT5');
  }
  h4{
    color: #0D73D6;
    font-size: 18px;
    text-align: center;
    margin-top: 16px;
  }
  p{
    padding: 0 15px;
    line-height: 20px;
    font-size: 15px;
    color: rgba(0,0,0,0.8);
    margin-top: 10px;
  }
  .box{
    width: 100%;
    height: 24px;
    margin-top: 20px;
    text-align: center;
    padding: 0 10%;
    .btn{
      min-width: 120px;
      height: 30px;
      line-height: 30px;
      margin: 5px;
      color: #0D73D6;
    }
    .btn-primary{
      color: #fff;
    }
  }
  /deep/ .el-carousel__indicator--horizontal {
    padding: 12px 7px;
    .el-carousel__button {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: rgba(13, 115, 214, 1);
    }
  }
  //@include bg-image('../../assets/image/home/footer-slider-bg');
}
</style>
