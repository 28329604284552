<template>
  <section class="disclaimer">
    <div class="container">
      <p>{{$t("footer.address")}}</p>
      <p class="warning">
        {{$t("footer.warning")}}：
        <br>
        {{$t("footer.describe")}}
      </p>
    </div>
    <div class="copyright flex flex__center">
      Copyright © 2017 - 2020 . All Rights Reserved.
    </div>
  </section>
</template>

<script>
export default {
  name: 'disclaimer',
}
</script>

<style scoped lang="scss">
.disclaimer {
  margin-top: 52px;
  p {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 20px;
  }
  .warning {
    margin-top: 32px;
    color: rgba(255, 255, 255, .6);
  }
  .copyright {
    height: 56px;
    margin-top: 48px;
    background: rgba(255, 255, 255, 0.08);
  }
}

@media screen and(max-width: 992px) {
  .container{
    width: 100vw;
    padding: 20px 15px 0 15px;

  }
  .copyright{
    height: 36px !important;
  }
  .disclaimer{
    margin-top: 0;
  }
}
</style>
