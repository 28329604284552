<template>
  <div class="warpper">
    <hottest class="hot-forex" :title="$t('deal.forex.title13')" :apiCodes="['BTCUSD','BCHUSD','ETHUSD',' LTCUSD']"></hottest>
    <div class="currency-rule">
      <h4>{{$t("deal.forex.title14")}}</h4>
      <CurrencyRule :source="currencyRule" />
    </div>
    <div class="other-product">
      <h4>{{$t("deal.forex.title4")}}</h4>
      <OtherProduct :source="otherProduct" />
    </div>
  </div>
</template>

<script>
import CurrencyRule from './currency-rule'
import OtherProduct from './other-product'
import Hottest from '../hottest'
export default {
  name: 'digital-currency',
  components: {
    CurrencyRule,
    OtherProduct,
    Hottest
  },
  data() {
    return {
      time: ['06', '08', '10', '12', '14', '16', '18', '20', '22', '24','02', '04', '06'],
      otherProduct: '/deal/DigitalCurrency'
    }
  },
  computed: {
    currencyRule() {
      return [
        {
          symbols: 'BCHUSD',
          contract: 100,
          digits: 2,
          minimum: 0.01,
          maximun: 50,
          step: 0.01,
          leverage: '1:3'
        },
        {
          symbols: 'BTCUSD',
          contract: 1,
          digits: 2,
          minimum: 0.01,
          maximun: 50,
          step: 0.01,
          leverage: '1:3'
        },
        {
          symbols: 'LTCUSD',
          contract: 100,
          digits: 2,
          minimum: 0.01,
          maximun: 50,
          step: 0.01,
          leverage: '1:3'
        },
        {
          symbols: 'ETHUSD',
          contract: 10,
          digits: 2,
          minimum: 0.01,
          maximun: 50,
          step: 0.01,
          leverage: '1:3'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.warpper{
  h4 {
    height: 50px;
    font-size: 36px;
    font-weight: bold;
    color: #000000;
    line-height: 50px;
    text-align: center;
    margin: 80px 0 38px 0;
  }
}
.hot-warpper{
  width:1120px;
  margin: 0 auto;
  display: flex;
  .item{
    flex: 1;
    box-shadow: 0px 0px 20px 0px rgba(33,66,171,0.1500);
    border-radius: 2px;
    margin-right: 20px;
    padding: 24px;
    box-sizing: border-box;
    &:last-child{
      margin-right: 0;
    }
    .list{
      flex: 1;
      &.price p:nth-child(2){
        color: #000;
      }
      &.day p:nth-child(2){
        color: #F85247;
      }
      &.week p:nth-child(2){
        color: #F85247;
      }
    }
    .tit{
      font-size: 20px;
      font-weight: 600;
      color: #000000;
    }
    .data{
      display: flex;
      color: #000;
      margin-top: 22px;
      .subTit{
        color: rgba($color: #000000, $alpha: 0.6);
      }
      .num{
        margin-top: 10px;
      }
    }
    .echarts{
      height: 100px;
      color: #000;
      margin-bottom: 12px;
      margin-top: 27px;
    }
  }
}
.hot-forex{
  padding-top: 1px;
  background-color: #F5F6F8;
  padding-bottom: 100px;
}
.currency-rule{
  padding-top: 1px;
  padding-bottom: 100px;
}
.other-product{
  padding-bottom: 100px;
  padding-top: 1px;
  background-color: #F5F6F8;
}
@media screen and(max-width: 992px) {
  .hot-forex{
    padding-bottom: 20px;
  }
  .other-hot-forex,.currency-rule,.other-product{
    padding-bottom: 20px !important;
    h4{
      width: 100%;
      height: 22.5px;
      font-size: 20px !important;
      font-family: Barlow-ExtraBold, Barlow;
      font-weight: 800;
      color: #000;
      line-height: 22.5px;
      text-align: center;
      margin: 20px 0 !important;
    }
  }
}
</style>