<template>
    <div>
        <div class="desktop box">
            <h4>{{$t("platform.mt5.title1")}}</h4>
            <div class="desktop-pic"></div>
            <p v-html="describe"></p>
            <button class="btn btn-primary" >{{$t("platform.mt5.apply")}}</button>
        </div>
        <div class="mac box">
            <h4>{{$t("platform.mt5.title2")}}</h4>
            <div class="mac-pic"></div>
            <p v-html="describe2"></p>
            <button class="btn btn-primary" >{{$t("platform.mt5.apply")}}</button>
        </div>
        <div class="box ipad">
            <h4>{{$t("platform.mt5.title3")}}</h4>
            <div class="ipad-pic"></div>
            <p v-html="describe3"></p>
            <button class="btn btn-primary">{{$t("platform.mt5.apply")}}</button>
        </div>
        <div class="box mobile">
            <h4>{{$t("platform.mt5.title4")}}</h4>
            <div class="mobile-pic"></div>
            <p v-html="describe4"></p>
            <button class="btn btn-primary">{{$t("platform.mt5.apply")}}</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "view-MT5-phone",
        computed: {
            describe() {
                return this.$t("platform.mt5.describe1")
            },
            describe2() {
                return this.$t("platform.mt5.describe2")
            },
            describe3() {
                return this.$t("platform.mt5.describe3")
            },
            describe4() {
                return this.$t("platform.mt5.describe4")
            }
        }
    }
</script>

<style scoped lang="scss">
    .box{
        width: 100vw;
        height: auto;
        padding: 20px 15px;
        text-align: center;
        h4{
            width: 100%;
            height: 22.5px;
            line-height: 22.5px;
            text-align: center;
            color: #000000;
            font-size: 20px;
            font-weight: 600;
        }
        p {
            width: 100%;
            height: auto;
            font-size: 13px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.8);
            line-height: 20px;
            margin-top: 10px;
        }
        .btn{
            height: 30px;
            line-height: 30px;
            margin: 20px 2px 0 2px;
        }
    }
    .desktop{
        .desktop-pic{
            width: 190px;
            height: 95px;
            background-size: cover;
            background-position: center center;
            @include bg-image('../../assets/image/platform/device-MT5');
            margin: 20px auto;
        }
    }
    .mac{
        @include bg-image('../../assets/image/platform/mac-bg');
        background-size: cover;
        background-position: left top;
        .mac-pic{
            width: 135px;
            height: 105px;
            background-size: cover;
            background-position: center center;
            @include bg-image('../../assets/image/platform/flexible-MT5');
            margin: 20px auto;
        }
    }
    .ipad{
        .ipad-pic{
            width: 150px;
            height: 110px;
            background-size: cover;
            background-position: center center;
            @include bg-image('../../assets/image/platform/time-MT5');
            margin: 20px auto;
        }
    }
    .mobile{
        @include bg-image('../../assets/image/platform/mobile-bg');
        background-size: cover;
        background-position: left top;
        .mobile-pic{
            width: 135px;
            height: 115px;
            background-size: cover;
            background-position: center center;
            @include bg-image('../../assets/image/platform/deep-MT5');
            margin: 20px auto;
        }
    }
</style>