<template>
  <div class="warpper">
    <hottest class="hot-forex" :title="$t('deal.forex.title9')" :apiCodes="['UK100', 'AU200', 'DE30', 'FR40']"></hottest>
    <div class="other-hot-forex forex-table__wrapper">
      <h4>{{$t("deal.forex.title7")}}</h4>
      <markets-table class="container forex-markets-table" :tabItem="forexItem"></markets-table>
    </div>
    <div class="currency-rule">
      <h4>{{$t("deal.forex.title8")}}</h4>
      <CurrencyRule :source="currencyRule" />
    </div>
    <div class="other-product">
      <h4>{{$t("deal.forex.title4")}}</h4>
      <OtherProduct :source="otherProduct" />
    </div>
  </div>
</template>

<script>
import CurrencyRule from './currency-rule'
import OtherProduct from './other-product'
import Hottest from '../hottest'
import MarketsTable from '../base/markets-table.vue'
export default {
  name: 'Forex',
  components: {
    CurrencyRule,
    OtherProduct,
    Hottest,
    MarketsTable
  },
  data() {
    return {
      time: ['06', '08', '10', '12', '14', '16', '18', '20', '22', '24','02', '04', '06'],
      otherProduct: '/deal/exponent'
    }
  },
  computed: {
    forexItem() {
      return {
        tabName: 'Indices',
        varieties: [
          { name: 'EU50', apiCode: 'EU50' },
          { name: 'ES35', apiCode: 'ES35' },
          { name: 'HK50', apiCode: 'HK50' },
          { name: 'JP225', apiCode: 'JP225' },
          { name: 'UT100', apiCode: 'UT100' },
          { name: 'US30', apiCode: 'US30' },
          { name: 'US500', apiCode: 'US500' },
          { name: 'CHINA50Roll', apiCode: 'CHINA50Roll' }
        ]
      }
    },
    currencyRule() {
      return [
        {
          symbols: 'HK50',
          contract: 1,
          digits: 2,
          minimum: 0.1,
          maximun: 100,
          step: 0.1,
          leverage: '1:200'
        },
        {
          symbols: 'US500',
          contract: 10,
          digits: 2,
          minimum: 0.1,
          maximun: 100,
          step: 0.1,
          leverage: '1:200'
        },
        {
          symbols: 'DE30',
          contract: 1,
          digits: 2,
          minimum: 0.1,
          maximun: 100,
          step: 0.1,
          leverage: '1:200'
        },
        {
          symbols: 'JP225',
          contract: 10,
          digits: 2,
          minimum: 0.1,
          maximun: 100,
          step: 0.1,
          leverage: '1:200'
        },
        {
          symbols: 'AU200',
          contract: 1,
          digits: 2,
          minimum: 0.1,
          maximun: 100,
          step: 0.1,
          leverage: '1:200'
        },
        {
          symbols: 'FR40',
          contract: 1,
          digits: 2,
          minimum: 0.1,
          maximun: 100,
          step: 0.1,
          leverage: '1:200'
        },
        {
          symbols: 'UK100',
          contract: 1,
          digits: 2,
          minimum: 0.1,
          maximun: 100,
          step: 0.1,
          leverage: '1:200'
        },
        {
          symbols: 'US30',
          contract: 1,
          digits: 2,
          minimum: 0.1,
          maximun: 100,
          step: 0.1,
          leverage: '1:200'
        },
        {
          symbols: 'EU50',
          contract: 1,
          digits: 2,
          minimum: 0.1,
          maximun: 100,
          step: 0.1,
          leverage: '1:200'
        },
        {
          symbols: 'ESP35',
          contract: 1,
          digits: 2,
          minimum: 0.1,
          maximun: 100,
          step: 0.1,
          leverage: '1:200'
        },
        {
          symbols: 'UT100',
          contract: 1,
          digits: 2,
          minimum: 0.1,
          maximun: 100,
          step: 0.1,
          leverage: '1:200'
        },
        {
          symbols: 'CHINA50',
          contract: 10,
          digits: 2,
          minimum: 0.1,
          maximun: 100,
          step: 0.1,
          leverage: '1:50'
        },
        // {
        //   symbols: 'EURUSD',
        //   contract: 100000,
        //   digits: 5,
        //   minimum: 0.01,
        //   maximun: 50,
        //   step: 0.01,
        //   leverage: '1:200'
        // },
        // {
        //   symbols: 'GBUPSD',
        //   contract: 100000,
        //   digits: 5,
        //   minimum: 0.01,
        //   maximun: 50,
        //   step: 0.01,
        //   leverage: '1:200'
        // },
        // {
        //   symbols: 'AUDUSD',
        //   contract: 100000,
        //   digits: 5,
        //   minimum: 0.01,
        //   maximun: 50,
        //   step: 0.01,
        //   leverage: '1:200'
        // },
        // {
        //   symbols: 'USDJPY',
        //   contract: 100000,
        //   digits: 5,
        //   minimum: 0.01,
        //   maximun: 50,
        //   step: 0.01,
        //   leverage: '1:200'
        // },
        // {
        //   symbols: 'USDCAD',
        //   contract: 100000,
        //   digits: 5,
        //   minimum: 0.01,
        //   maximun: 50,
        //   step: 0.01,
        //   leverage: '1:200'
        // },
        // {
        //   symbols: 'NZDUSD',
        //   contract: 100000,
        //   digits: 5,
        //   minimum: 0.01,
        //   maximun: 50,
        //   step: 0.01,
        //   leverage: '1:200'
        // },
        // {
        //   symbols: 'USDCHF',
        //   contract: 100000,
        //   digits: 5,
        //   minimum: 0.01,
        //   maximun: 50,
        //   step: 0.01,
        //   leverage: '1:200'
        // }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.warpper{
  h4 {
    height: 50px;
    font-size: 36px;
    font-weight: bold;
    color: #000000;
    line-height: 50px;
    text-align: center;
    margin: 80px 0 38px 0;
  }
}
.hot-warpper{
  width:1120px;
  margin: 0 auto;
  display: flex;
  .item{
    flex: 1;
    box-shadow: 0px 0px 20px 0px rgba(33,66,171,0.1500);
    border-radius: 2px;
    margin-right: 20px;
    padding: 24px;
    box-sizing: border-box;
    &:last-child{
      margin-right: 0;
    }
    .list{
      flex: 1;
      &.price p:nth-child(2){
        color: #000;
      }
      &.day p:nth-child(2){
        color: #F85247;
      }
      &.week p:nth-child(2){
        color: #F85247;
      }
    }
    .tit{
      font-size: 20px;
      font-weight: 600;
      color: #000000;
    }
    .data{
      display: flex;
      color: #000;
      margin-top: 22px;
      .subTit{
        color: rgba($color: #000000, $alpha: 0.6);
      }
      .num{
        margin-top: 10px;
      }
    }
    .echarts{
      height: 100px;
      color: #000;
      margin-bottom: 12px;
      margin-top: 27px;
    }
  }
}
.hot-forex{
  padding-top: 1px;
  background-color: #F5F6F8;
}
.other-hot-forex{
  padding-bottom: 100px;
  padding-top: 1px;
  background-color: #F5F6F8;
}
.currency-rule{
  padding-top: 1px;
  padding-bottom: 100px;
}
.other-product{
  padding-bottom: 100px;
  padding-top: 1px;
  background-color: #F5F6F8;
}
@media screen and(max-width: 992px) {
  .hot-forex{
    padding-bottom: 20px;
  }
  .other-hot-forex,.currency-rule,.other-product{
    padding-bottom: 20px !important;
    h4{
      width: 100%;
      height: 22.5px;
      font-size: 20px !important;
      font-family: Barlow-ExtraBold, Barlow;
      font-weight: 800;
      color: #000;
      line-height: 22.5px;
      text-align: center;
      margin: 20px 0 !important;
    }
  }
}
</style>