<template>
  <div class="fund-info">
    <div class="warpper">
      <div style="display:flex;">
        <div class="left">
          <div class="info-box">
            <h3>{{$t("support.fundInfo.title")}}</h3>
            <p>{{$t("support.fundInfo.describe")}}</p>
            <button class="btn btn-primary" @click="$open('signUp', $i18n.locale)">{{$t("support.registerNow")}}</button>
          </div>
        </div>
        <div class="right">
          <img src="@/assets/image/support/fund-info.png">
        </div>
      </div>
      <div class="gold-list">
        <div class="list" v-for="(item, index) in gold" :key="index">
          <div class="name">{{item.name}}</div>
          <div class="des">{{item.des}}</div>
          <div class="num">{{item.num}}{{item.name}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {

    }
  },
  computed: {
    gold() {
      return [
        {
          name: 'USDT',
          des: this.$t('support.fundInfo.golden'),
          num: '10'
        },
        // {
        //   name: 'AUD',
        //   des: this.$t('support.fundInfo.golden'),
        //   num: '200'
        // }, {
        //   name: 'EUR',
        //   des: this.$t('support.fundInfo.golden'),
        //   num: '300'
        // }, {
        //   name: 'GBP',
        //   des: this.$t('support.fundInfo.golden'),
        //   num: '400'
        // }, {
        //   name: 'NZD',
        //   des: this.$t('support.fundInfo.golden'),
        //   num: '500'
        // }
      ]
    }
  }
}
</script>
<style scoped lang="scss">
.fund-info{
  padding-bottom: 100px;
  .warpper{
    width: 1120px;
    margin: 0 auto;
    padding-top: 80px;
  }
  .left,.right{
    flex: 1;
  }
  .left{
    display: flex;
    align-items: center;
    .info-box{
      display: inline-block;
      h3{
        font-size: 48px;
        font-weight: 500;
        color: #000000;
        line-height: 65px;
      }
      p{
        font-size: 16px;
        font-weight: 400;
        margin-top: 16px;
        color: rgba($color: #000000, $alpha: 0.6);
        line-height: 22px;
      }
      button{
        margin-top: 40px;
      }
    }
  }
  .right{
    img{
      margin-left: 74px;
    }
  }
  .gold-list{
    display: flex;
    margin-top: 20px;
    .list{
      flex: 1;
      text-align: center;
      background: #FFFFFF;
      box-shadow: 0px 0px 20px 0px rgba(33,66,171,0.1500);
      border-radius: 20px;
      margin-right: 55px;
      padding: 20px;
      box-sizing: border-box;
      &:last-child{
        margin-right: 0;
      }
      .name{
        font-size: 36px;
        font-weight: 500;
        color: #0D73D6;
        line-height: 50px;
      }
      .des{
        font-size: 16px;
        font-weight: 400;
        color: rgba($color: #000000, $alpha: 0.6);
        line-height: 22px;
        margin-top: 9px;
      }
      .num{
        font-size: 36px;
        font-weight: 500;
        color: #000000;
        line-height: 50px;
        margin-top: 9px;
      }
    }
  }
}

@media screen and (min-width: 1200px) {

}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .fund-info{
    .warpper{
      width: 95vw;

    }
  }

}

@media screen and (min-width: 768px) and (max-width: 992px) {

}

@media screen and (max-width: 768px) {

}
</style>