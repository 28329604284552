<template>
  <div>
    <div class="container instruments__wrapper">
      <h1 class="title">{{ hottestTitle }}</h1>
      <div class="flex__justify-space-between instrument-block__wrapper">
        <hottest-block :apiCode="apiCode" v-for="apiCode in apiCodes" :key="apiCode"></hottest-block>
      </div>
    </div>
  </div>
</template>

<script>
import HottestBlock from './hottest-block'

export default {
  name: 'HottestForex',
  components: {
    HottestBlock
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    apiCodes: {
      type: Array,
      default: () => ['XAUUSD', 'EURUSD', 'GBPUSD', 'AUDUSD']
    }
  },

  computed: {
    hottestTitle() {
      return this.title !== '' ? this.title : this.$t('common.hottestTitle')
    }
  }
}
</script>

<style scoped lang="scss">
h1.title {
  height: 50px;
  font-size: 36px;
  color: #000000;
  line-height: 50px;
  text-align: center;
  margin: 80px 0 38px 0;
  @include title-bold();
}

.instruments__wrapper {
  margin-top: 117px;
  .instrument-block__wrapper {
    margin-top: 40px;
    display: flex;
  }
}

/* 大屏幕（大桌面显示器，大于等于 1200px）*/
@media screen and (min-width: 1200px) {
  /* 当前网站以大屏幕方式设计开发 大于1200px 不作变动 */
}

/* 中等屏幕（桌面显示器，大于等于 992px 小于 1200px） */
@media screen and (min-width: 992px) and (max-width: 1200px) {
}

/* 小屏幕（平板，大于等于 768px 小于 992px） */
@media screen and (min-width: 768px) and (max-width: 992px) {
}

/* 超小屏幕（手机，小于 768px） */
@media screen and (max-width: 768px) {
  .container{
    width: 100vw;
    padding-bottom: 30px !important;
  }
  h1{
    width: 100%;
    height: 22.5px;
    font-size: 20px !important;
    font-family: Barlow-ExtraBold, Barlow;
    font-weight: 800;
    color: #ffffff;
    line-height: 22.5px;
    text-align: center;
    margin: 20px 0 !important;

  }
  .instruments__wrapper {
    margin-top: 0px;
    padding: 0 16px;
    .instrument-block__wrapper {
      margin-top: 28px;
      display: block;
    }
  }
}
</style>
