<template>
  <div>
    <homer-slider />
    <gpb-usd />
    <advantage />
    <footer-slider />
    <start-trading-slider />
    <contact-us />
  </div>
</template>

<script>
import HomerSlider from '@/components/home/slider'
import GpbUsd from '@/components/home/gpb-usd/gpb-usd'
import Advantage from '@/components/home/advantage'
import StartTradingSlider from '@/components/home/start-trading-slider'
import ContactUs from '@/components/home/contact-us'
import FooterSlider from '@/components/home/footer-slider'

export default {
  name: 'Home',
  components: {
    HomerSlider,
    GpbUsd,
    Advantage,
    // Markets,
    StartTradingSlider,
    ContactUs,
    // NewsList,
    FooterSlider
  },
}
</script>
