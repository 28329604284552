<template>
  <div class="warpper">
    <DesktopMT4 />
    <MacMT4 />
    <IpadMT4 />
    <MobileMT4 />
  </div>
</template>

<script>
import DesktopMT4 from './desktop-MT4'
import IpadMT4 from './ipad-MT4'
import MacMT4 from './mac-MT4'
import MobileMT4 from './mobile-MT4'
export default {
  name: 'Forex',
  components: {
    DesktopMT4,
    IpadMT4,
    MacMT4,
    MobileMT4
  },
  data() {
    return {}
  }
}
</script>

<style scoped lang="scss"></style>