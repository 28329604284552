<template>
  <div class="fund-question">
    <h3>{{$t('support.fundQuestion.title')}}</h3>
    <div class="warpper">
      <el-collapse v-model="activeName" accordion>
        <el-collapse-item v-for="item in collapse" :key="item.name" :name="item.name">
          <template slot="title">
            <span :class="[activeName === item.name ? 'collapse-title-active' : 'collapse-title']">{{item.des}}</span>
          </template>
          <div class="con">{{item.content}}</div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
export default {
  components:{},
  data(){
    return {
      activeName: '1'
    }
  },
  computed: {
    collapse() {
      return [
        {
          name: '1',
          des: this.$t('support.fundQuestion.des11'),
          content: this.$t('support.fundQuestion.content11')
        },{
          name: '2',
          des: this.$t('support.fundQuestion.des12'),
          content: this.$t('support.fundQuestion.content12')
        },{
          name: '3',
          des: this.$t('support.fundQuestion.des13'),
          content: this.$t('support.fundQuestion.content13')
        }
        // {
        //   name: '1',
        //   des: this.$t('support.fundQuestion.des1'),
        //   content: this.$t('support.fundQuestion.content')
        // },{
        //   name: '2',
        //   des: this.$t('support.fundQuestion.des2'),
        //   content: this.$t('support.fundQuestion.content')
        // },{
        //   name: '3',
        //   des: this.$t('support.fundQuestion.des3'),
        //   content: this.$t('support.fundQuestion.content')
        // },{
        //   name: '4',
        //   des: this.$t('support.fundQuestion.des4'),
        //   content: this.$t('support.fundQuestion.content')
        // },{
        //   name: '5',
        //   des: this.$t('support.fundQuestion.des5'),
        //   content: this.$t('support.fundQuestion.content')
        // },{
        //   name: '6',
        //   des: this.$t('support.fundQuestion.des6'),
        //   content: this.$t('support.fundQuestion.content')
        // },{
        //   name: '7',
        //   des: this.$t('support.fundQuestion.des7'),
        //   content: this.$t('support.fundQuestion.content')
        // },{
        //   name: '8',
        //   des: this.$t('support.fundQuestion.des8'),
        //   content: this.$t('support.fundQuestion.content')
        // },{
        //   name: '9',
        //   des: this.$t('support.fundQuestion.des9'),
        //   content: this.$t('support.fundQuestion.content')
        // },{
        //   name: '10',
        //   des: this.$t('support.fundQuestion.des10'),
        //   content: this.$t('support.fundQuestion.content')
        // },
      ]
    }
  }
}
</script>
<style scoped lang="scss">
.fund-question{
  padding-top: 80px;
  padding-bottom: 110px;
  h3{
    font-size: 36px;
    font-weight: bold;
    color: #0B0B0B;
    line-height: 50px;
    text-align: center;
  }
  .warpper{
    width: 1120px;
    margin: 0 auto;
    .collapse-title{
      font-size: 16px;
      font-weight: 400;
      color: rgba($color: #000000, $alpha: 0.6);
      line-height: 22px;
    }
    .collapse-title-active{
      font-size: 16px;
      font-weight: 400;
      color: #0D73D6;
      line-height: 22px;
    }
    .con{
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      line-height: 24px;
    }
  }
}
/deep/.el-collapse-item{
  margin-bottom: 6px;
}
/deep/ .el-collapse-item__header{
  border-bottom: none;
  background-color: #F5F6F8;
}

@media screen and (min-width: 1200px) {

}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .fund-question{
    .warpper{
      width: 95vw;

    }
  }

}

@media screen and (max-width: 992px) {
  .fund-question{
    padding-top: 20px;
    padding-bottom: 20px;
    h3{
      width: 100%;
      height: 22.5px;
      line-height: 22.5px;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .warpper{
      width: 95vw;
      .collapse-title{
        font-size: 14px;
      }
      .con{
        font-size: 13px;
      }
    }
  }
}
</style>