<template>
  <div class="warpper">
    <div class="trade-time">
      <h4>{{$t("deal.forex.title1")}}</h4>
      <div class="tree-warpper">
        <div class="map"></div>
        <div class="city" :style="{'--bgColor': '#EF8075', '--width': widthr, '--widthr': widthr, '--top': 0, '--left': 0}">
          <div class="des">{{$t("deal.forex.Sydney")}} 06:00~14:00</div>
          <div class="rectangle">
            <div class="left"></div>
            <div class="right"></div>
          </div>
        </div>
        <div class="city" :style="{'--bgColor': '#F7AA52', '--width': widthr , '--widthr': widthr, '--top': '83px', '--left': positionNum(95)}">
          <div class="des">{{$t("deal.forex.Tokyo")}} 08:00~16:00</div>
          <div class="rectangle">
            <div class="left"></div>
            <div class="right"></div>
          </div>
        </div>
        <div class="city" :style="{'--bgColor': '#7AC68C', '--width': widthr, '--widthr': widthr, '--top': '164px', '--left': positionNum(143)}">
          <div class="des">{{$t("deal.forex.HongKong")}} 09:00~17:00</div>
          <div class="rectangle">
            <div class="left"></div>
            <div class="right"></div>
          </div>
        </div>
        <div class="city" :style="{'--bgColor': '#4EA1F1', '--width': widthr, '--widthr': widthr, '--top': '245px', '--left': positionNum(469)}">
          <div class="des">{{$t("deal.forex.Frankfurt")}} 16:00~00:00</div>
          <div class="rectangle">
            <div class="left"></div>
            <div class="right"></div>
          </div>
        </div>
        <div class="city" :style="{'--bgColor': '#C78DD9', '--width': widthr, '--widthr': widthr, '--top': '326px', '--left': positionNum(469)}">
          <div class="des">{{$t("deal.forex.Paris")}} 16:00~00:00</div>
          <div class="rectangle">
            <div class="left"></div>
            <div class="right"></div>
          </div>
        </div>
        <div class="city" :style="{'--bgColor': '#C59973', '--width': widthr, '--widthr': widthr, '--top': '407px', '--left': positionNum(515)}">
          <div class="des">{{$t("deal.forex.London")}} 17:00~01:00</div>
          <div class="rectangle">
            <div class="left"></div>
            <div class="right"></div>
          </div>
        </div>
        <div class="city" :style="{'--bgColor': '#70CDD3', '--width': widthr, '--widthr': widthr, '--top': '488px', '--left': positionNum(654)}">
          <div class="des">{{$t("deal.forex.NewYork")}} 20:00~04:00</div>
          <div class="rectangle">
            <div class="left"></div>
            <div class="right"></div>
          </div>
        </div>
        <div class="city" :style="{'--bgColor': '#E35C8D', '--width': widthr, '--widthr': widthr, '--top': '569px', '--left': positionNum(700)}">
          <div class="des">{{$t("deal.forex.Chicago")}} 21:00~05:00</div>
          <div class="rectangle">
            <div class="left"></div>
            <div class="right"></div>
          </div>
        </div>
        <div class="city" :style="{'--bgColor': '#999CDB', '--width': widthr, '--widthr': widthr, '--top': '650px', '--left': positionNum(794), '--leftg': positionNum(-794)}">
          <div class="des">{{$t("deal.forex.SanFrancisco")}} 23:00~07:00</div>
          <div class="rectangle">
            <div class="left"></div>
            <div class="right"></div>
          </div>
        </div>
        <div class="city" :style="{'--bgColor': '#FFD351', '--width': widthr, '--widthr': widthr, '--top': '731px', '--left': positionNum(794), '--leftg': positionNum(-794)}">
          <div class="des">{{$t("deal.forex.LosAngeles")}} 23:00~07:00</div>
          <div class="rectangle">
            <div class="left"></div>
            <div class="right"></div>
          </div>
        </div>
      </div>
      <div class="time-line">
        <div class="scale" v-for="(item, index) in time" :key="index">{{item}}</div>
      </div>
    </div>
<!--    <div class="forex-gpd-chart">-->
<!--      <div class="box">-->
<!--        <forex-gpd-chart :indexItem="forexIndexItem"></forex-gpd-chart>-->
<!--      </div>-->
<!--    </div>-->
<!--    <forex-gpd-chart></forex-gpd-chart>-->
    <hottest class="hot-forex" :title="$t('deal.forex.title12')"></hottest>
    <div class="other-hot-forex forex-table__wrapper">
      <h4>{{$t("deal.forex.title2")}}</h4>
      <markets-table class="container forex-markets-table" :tabItem="forexItem"></markets-table>
    </div>
    <div class="currency-rule">
      <h4>{{$t("deal.forex.title3")}}</h4>
      <CurrencyRule :source="currencyRule" />
    </div>
    <div class="other-product">
      <h4>{{$t("deal.forex.title4")}}</h4>
      <OtherProduct :source="otherProduct" />
    </div>
  </div>
</template>

<script>
import CurrencyRule from './currency-rule'
import OtherProduct from './other-product'
import Hottest from '../hottest'
import MarketsTable from '../base/markets-table.vue'
import ForexGpdChart from './forex-gpb-usd/forex-gpd-chart'
import GpbUsd from '../home/gpb-usd/gpb-usd'
import GpbChart from '../home/gpb-usd/gpb-chart'
export default {
  name: 'Forex',
  components: {
    GpbChart,
    GpbUsd,
    ForexGpdChart,
    CurrencyRule,
    OtherProduct,
    Hottest,
    MarketsTable
  },
  data() {
    return {
      time: ['06', '08', '10', '12', '14', '16', '18', '20', '22', '24','02', '04', '06'],
      otherProduct: '/deal/forex',
      hotForex: [{
        name: 'XAUUSD',
        price: 121.11,
        day: '12',
        week: '36.12',
        chartData: [{
          time: '8:39',
          data: 1.1
        }, {
          time: '9:39',
          data: 2.1
        }, {
          time: '10:39',
          data: 0.1
        }, {
          time: '13:39',
          data: 8.1
        }, {
          time: '16:39',
          data: 4.1
        }]
      }, {
        name: 'XAUUSD',
        price: 121.11,
        day: '12',
        week: '36.12',
        chartData: [{
          time: '6:39',
          data: 4.1
        }, {
          time: '9:39',
          data: 7.1
        }, {
          time: '10:39',
          data: 3.1
        }, {
          time: '13:39',
          data: 5.1
        }, {
          time: '16:39',
          data: 9.1
        }]
      }, {
        name: 'XAUUSD',
        price: 121.11,
        day: '12',
        week: '36.12',
        chartData: [{
          time: '4:39',
          data: 1.6
        }, {
          time: '7:39',
          data: 2.6
        }, {
          time: '10:39',
          data: 0.9
        }, {
          time: '13:39',
          data: 5.1
        }, {
          time: '16:39',
          data: 3.1
        }]
      }, {
        name: 'XAUUSD',
        price: 121.11,
        day: '12',
        week: '36.12',
        chartData: [{
          time: '5:39',
          data: 1.1
        }, {
          time: '10:39',
          data: 6.1
        }, {
          time: '11:39',
          data: 2.1
        }, {
          time: '14:39',
          data: 5.2
        }, {
          time: '16:39',
          data: 4.9
        }]
      }],
      forexIndexItem: {}
    }
  },
  computed: {
    forexItem() {
      return {
        tabName: 'Forex',
        varieties: [
          { name: 'USDJPY', apiCode: 'USDJPY' },
          { name: 'USDCHF', apiCode: 'USDCHF' },
          { name: 'AUDCAD', apiCode: 'AUDCAD' },
          { name: 'AUDJPY', apiCode: 'AUDJPY' },
          { name: 'AUDNZD', apiCode: 'AUDNZD' },
          { name: 'AUDCHF', apiCode: 'AUDCHF' },
          { name: 'EURAUD', apiCode: 'EURAUD' },
          { name: 'EURCAD', apiCode: 'EURCAD' },
          { name: 'EURGBP', apiCode: 'EURGBP' },
          { name: 'EURJPY', apiCode: 'EURJPY' },
          { name: 'EURNZD', apiCode: 'EURNZD' },
          { name: 'EURCHF', apiCode: 'EURCHF' },
          { name: 'CHFJPY', apiCode: 'CHFJPY' },
          { name: 'NZDCAD', apiCode: 'NZDCAD' },
          { name: 'NZDCHF', apiCode: 'NZDCHF' },
          { name: 'NZDJPY', apiCode: 'NZDJPY' },
          { name: 'CADJPY', apiCode: 'CADJPY' },
          { name: 'CADCHF', apiCode: 'CADCHF' },
          { name: 'GBPCHF', apiCode: 'GBPCHF' },
          { name: 'GBPAUD', apiCode: 'GBPAUD' },
          { name: 'GBPCAD', apiCode: 'GBPCAD' },
          { name: 'GBPJPY', apiCode: 'GBPJPY' },
          { name: 'USDHKD', apiCode: 'USDHKD' },
          { name: 'USDCNH', apiCode: 'USDCNH' }
        ]
      }
    },
    currencyRule() {
      return [
        {
          symbols: 'EURUSD',
          contract: 100000,
          digits: 5,
          minimum: 0.01,
          maximun: 50,
          step: 0.01,
          leverage: '1:200'
        },
        {
          symbols: 'GBUPSD',
          contract: 100000,
          digits: 5,
          minimum: 0.01,
          maximun: 50,
          step: 0.01,
          leverage: '1:200'
        },
        {
          symbols: 'AUDUSD',
          contract: 100000,
          digits: 5,
          minimum: 0.01,
          maximun: 50,
          step: 0.01,
          leverage: '1:200'
        },
        {
          symbols: 'USDJPY',
          contract: 100000,
          digits: 5,
          minimum: 0.01,
          maximun: 50,
          step: 0.01,
          leverage: '1:200'
        },
        {
          symbols: 'USDCAD',
          contract: 100000,
          digits: 5,
          minimum: 0.01,
          maximun: 50,
          step: 0.01,
          leverage: '1:200'
        },
        {
          symbols: 'NZDUSD',
          contract: 100000,
          digits: 5,
          minimum: 0.01,
          maximun: 50,
          step: 0.01,
          leverage: '1:200'
        },
        {
          symbols: 'USDCHF',
          contract: 100000,
          digits: 5,
          minimum: 0.01,
          maximun: 50,
          step: 0.01,
          leverage: '1:200'
        },
        {
          symbols: 'Forex Cross',
          contract: 100000,
          digits: 5,
          minimum: 0.01,
          maximun: 50,
          step: 0.01,
          leverage: '1:200'
        },
      ]
    },
    screenWidth() {
      let width = localStorage.getItem('screen_width') || 1200
      return width
    },
    widthr() {
      let screenWidth = localStorage.getItem('screen_width'),width = '';
      if(screenWidth >= 1200) {
        width = '375px'
      } else {
        width = `${95 / 12 * 4}vw`
      }
      return width
    },

  },
  created() {
    if(Object.keys(this.$route.query).length > 0) {
      this.forexIndexItem = this.$route.query.name
    } else {
      this.forexIndexItem = this.forexItem.varieties[0].name

    }
  },
  methods: {
    positionNum(num) {
      let position = '' , screenWidth = (localStorage.getItem('screen_width')) * 0.95;
      if(localStorage.getItem('screen_width') >= 1200) {
        position =  `${num}px`
      } else {
        position =  `${screenWidth * (num / 1120)}px`
      }

      return position
    }
  }
}
</script>

<style scoped lang="scss">
.warpper{
  h4 {
    height: 50px;
    font-size: 36px;
    font-weight: bold;
    color: #000000;
    line-height: 50px;
    text-align: center;
    margin: 80px 0 38px 0;
  }
}
.trade-time {
  // background-color: #F5F6F8;
  padding-top: 1px;
  padding-bottom: 100px;
  .tree-warpper {
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 1120px;
    height: 800px;
    margin: 0 auto;
    border-top: 1px dashed #000;
    border-bottom: 1px dashed #000;
    position: relative;
    @include bg-image('../../assets/image/deal/tree');
    .map{
      width: 100%;
      height: 522px;
      border-top: 1px dashed #000;
      border-bottom: 1px dashed #000;
      @include bg-image('../../assets/image/deal/deal-map');
    }
    .city{
      position: absolute;
      top:var(--top);
      left:var(--left);
      display: flex;
      align-items: center;
      flex-direction: column;
      .des{
        padding: 7px 36px;
        color: #fff;
        text-align: center;
        display: inline-block;
        position: relative;
        margin-bottom: 15px;
        background: var(--bgColor);
        &::after{
          content: '';
          width: 0;
          height: 0;
          bottom: -10px;
          left: 50%;
          transform: translateX(-50%);
          border-top: 10px solid var(--bgColor);
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          position: absolute;
        }
      }
      .rectangle{
        display: flex;
        justify-content: space-between;
        .left{
          width: calc(var(--width) - var(--widthr));
          background: var(--bgColor);
          height: 20px;
          position: absolute;
          left: var(--leftg);
        }
        .right{
          width: var(--widthr);
          background: var(--bgColor);
          height: 20px;
        }
      }
    }
  }
  .time-line{
    width: 1120px;
    margin: 10px auto 0;
    .scale{
      display: inline-block;
      color: #7E7E7E;
      font-size: 16px;
      margin-right: 74px;
      &:last-child{
        margin-right: 0;
      }
    }
  }
}

.other-hot-forex{
  padding-bottom: 100px;
  padding-top: 1px;
  background-color: #F5F6F8;
}
.forex-gpd-chart{
  width: 100%;
  height: auto;
  background-color: #F5F6F8;
  padding-top: 80px;
  .box{
    width: 1120px;
    height: 480px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px 0px rgba(33,66,171,0.1500);
    border-radius: 6px;
    margin: 0 auto;
    /*padding: 14px 30px;*/
    /deep/.chart{
      width: 1000px;
    }
  }
}
.hot-forex{
  padding-top: 1px;
  background-color: #F5F6F8;
}
.currency-rule{
  padding-top: 1px;
  padding-bottom: 100px;
}
.other-product{
  padding-bottom: 100px;
  padding-top: 1px;
  background-color: #F5F6F8;
}

@media screen and (min-width: 992px) and(max-width: 1200px) {
  .trade-time{
    .tree-warpper{
      width: 95vw;
      background-size: 100% 100%;
    }
    .time-line{
      width: 95vw;
      .scale{
        margin-right: calc(95vw / 12 - 19.3px);
      }
    }

  }
  .forex-gpd-chart{
    width: 100%;
    .box{
      width: 95vw;
    }
  }
}

@media screen and(max-width: 992px) {
  .trade-time{
    .tree-warpper{
      width: 95vw;
      background-size: 100% 100%;
      .city .des{
        padding: 4px 2px;
        font-size: 12px;
      }
    }
    .time-line{
      width: 95vw;
      .scale{
        margin-right: calc(95vw / 12 - 19.3px);
      }
    }

  }
  .forex-gpd-chart{
    width: 100%;
    .box{
      width: 95vw;
    }
  }
  .other-hot-forex,.currency-rule,.other-product{
    padding-bottom: 20px !important;
    h4{
      width: 100%;
      height: 22.5px;
      font-size: 20px !important;
      font-family: Barlow-ExtraBold, Barlow;
      font-weight: 800;
      color: #000;
      line-height: 22.5px;
      text-align: center;
      margin: 20px 0 !important;
    }
  }
}
</style>