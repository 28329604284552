<template>
  <div class="agent-invite">
    <div class="warpper">
      <div class="left">
        <div class="info-box">
          <h3 v-html="title"></h3>
          <p>{{$t("support.agent.describe1")}}</p>
          <button class="btn btn-primary" @click="$open('signUp', $i18n.locale)">{{$t("support.registerNow")}}</button>
        </div>
      </div>
      <div class="right">
        <div class="img-box">
          <img src="@/assets/image/support/envelope.png" alt="">
          <div class="info" :class="{'info-locale': $i18n.locale == 'en'}">
            <h4>{{$t("support.agent.invitation")}}</h4>
            <div class="one ">{{$t("support.agent.inviteSincerely")}}</div>
            <div class="two">{{$t("support.agent.master")}}</div>
            <p>{{$t("support.agent.describe2")}}</p>
            <div class="three">{{$t("support.agent.describe3")}}</div>
            <div class="four" @click="$open('signUp', $i18n.locale)">
              <img src="@/assets/image/support/go.png">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
    }
  },
  computed: {
    title(){
      return this.$t('support.agent.title')
    }
  }
}
</script>
<style scoped lang="scss">
.agent-invite{
  height: 720px;
  background-size: 100% 100%;
  @include bg-image('../../assets/image/support/envelope-bg');
  .warpper{
    width: 1120px;
    margin: 0 auto;
    display: flex;
  }
  .left,.right{
    flex: 1;
  }
  .left{
    display: flex;
    align-items: center;
    // justify-content: center;
    .info-box{
      display: inline-block;
      h3{
        font-size: 48px;
        font-weight: 500;
        color: #000000;
        line-height: 65px;
      }
      p{
        font-size: 16px;
        font-weight: 400;
        margin-top: 16px;
        color: rgba($color: #000000, $alpha: 0.6);
        line-height: 22px;
      }
      button{
        margin-top: 40px;
      }
    }
  }
  .right{
    .img-box{
      display: inline-block;
      width: 515px;
      height: 620px;
      margin-top: 40px;
      position: relative;
      img{
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
      .info{
        width: 410px;
        position: absolute;
        top: 42px;
        left: 52px;
        text-align: center;
        padding: 15px;
        box-sizing: border-box;
        h4{
          font-size: 36px;
          color: #A96C43;
          line-height: 50px;
          letter-spacing: 6px;
          text-shadow: 0px 4px 10px rgba(160,116,77,0.5000);
        }
        .one{
          font-size: 16px;
          font-weight: 400;
          color: #474C61;
          line-height: 22px;
          margin-top: 23px;
        }
        .two{
          font-size: 24px;
          font-weight: 600;
          color: #474C61;
          line-height: 33px;
        }
        p{
          font-size: 14px;
          font-weight: 400;
          color: #474C61;
          line-height: 24px;
          text-align: left;
          margin-top: 24px;
        }
        .three{
          font-size: 14px;
          font-weight: 400;
          color: #474C61;
          line-height: 20px;
          margin-top: 40px;
        }
        .four{
          margin-top: 10px;
          img{
            width: 24px;
            height: 24px;
          }
        }
      }
      .info-locale{
        h4{
          line-height: 40px;
        }
        p{
          margin-top: 14px;
          line-height: 20px;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {

}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .agent-invite{
    .warpper{
      width: 95vw;

    }
  }

}

@media screen and (max-width: 992px) {
  .agent-invite{
    height: auto;
    background-size: 100% 68%;
    background-position: top center;
    background-repeat: no-repeat;
    .warpper{
      width: 100vw;
      display: block;
    }
    .left,.right{
      width: 100%;
      height: 50%;
      display: block;
      flex: none;
      overflow: hidden;
    }
    .left{

      .info-box{
        display: block;
        text-align: center;
        h3{
          width: 100%;
          height: auto;
          line-height: 22.5px;
          text-align: center;
          font-size: 20px;
          font-weight: 600;
          margin-top: 20px;
        }
        p{
          font-size: 15px;
          max-width: 100%;
          white-space: normal;
          margin-top: 10px;
          text-align: center;
        }
        button{
          margin-top: 20px;
          height: 30px;
          line-height: 30px;
          margin-bottom: 40px;
        }
      }
    }
    .right{
      /*padding-bottom: 20px;*/
      /*background-color: #D9DBDD;*/
      .img-box{
        display: block;
        width: 100%;
        height: 410px;
        margin: 0 auto;
        .info{
          width: calc(100% - 50px);
          left: 25px;
          top: 20px;

          h4{
            font-size: 18px;
            line-height: 25px;
          }
          .one{
            font-size: 13px;
            line-height: 16px;
            margin-top: 5px;
          }
          .two{
            font-size: 14px;
            line-height: 20px;
          }
          p{
            font-size: 12px;
            margin-top: 2px;
          }
          .three{
            font-size: 14px;
            margin-top: 20px;
          }
        }
      }
    }

  }
}
</style>