<template>
    <div class="container">
        <h4>{{$t('home.gpbUsd.title')}}</h4>
        <p class="container-p">{{$t('home.gpbUsd.service')}}</p>
        <gpb-chart></gpb-chart>
        <button class="btn btn-primary" @click="$open('signUp', $i18n.locale)">
            {{$t('common.registerNow')}}
        </button>
        <button class="btn btn-primary" style="margin-left: 12px;" @click="$open('signUp5', $i18n.locale)">
            {{$t('common.registerNow5')}}
        </button>
    </div>
</template>

<script>
    import GpbChart from './gpb-chart'
    export default {
        name: 'gpb-usd-phone',

        components: {
            GpbChart,
        },

        data() {
            return {
            }
        },

        computed: {
        },

        methods: {
        },
    }
</script>

<style scoped lang="scss">
    .container {
        width: 100vw;
        margin-top: 30px;
        padding: 0 15px;
        padding-bottom: 30px;
        text-align: center;
        h4{
            width: 100%;
            height: 22.5px;
            line-height: 22.5px;
            text-align: center;
            color: #000000;
            font-size: 20px;
            font-weight: 600;
        }
        .container-p{
            width: 100%;
            height: auto;
            overflow: hidden;
            line-height: 20px;
            color: rgba(0,0,0,0.8);
            margin-top: 18px;
            font-size: 14px;
        }
        .btn{
            min-width: 120px;
            height: 30px;
            line-height: 30px;
            margin-top: 16px;
        }
    }


</style>
