<template>
  <div :class="['home-slider', 'flex', 'flex-column', wrapperClass ,]">
    <div class="container">
      <home-header style="height:40px;margin-top: 52px"></home-header>
      <el-carousel :interval="500000" arrow="always" height="600px" @change="changeWrapperClassName">
        <el-carousel-item key="1">
          <h4 class="title" style="opacity: 0">{{$t('home.slider.banner1.service')}}</h4>
          <ul class="advantage-list flex" style="opacity: 0">
            <li v-for="(adv, index) of advantageList" :key="index" class="flex flex-column">
              <span>{{ adv.title }}</span>
              <span class="sub-text">{{ adv.subText }}</span>
            </li>
          </ul>
          <div class="flex btn-group flex__center" :style="{'margin-top': $i18n.locale == 'en' ? '60px' : '140px'}">
            <div class="btn active" :class="{'btn-locale': $i18n.locale == 'en'}" @click="$open('signUp', $i18n.locale)">
              <span class="icon"></span>
              <span>{{$t('home.slider.common.openRealAccount')}}</span>
            </div>
            <div class="btn" :class="{'btn-locale': $i18n.locale == 'en'}" @click="$open('signUp5', $i18n.locale)">
              <span class="icon"></span>
              <span>{{$t('home.slider.common.openRealAccount5')}}</span>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item key="2">
          <img src="../../assets/image/home/banner-title-en.png" alt="" srcset="" v-if="$i18n.locale == 'en'">
          <img src="../../assets/image/home/banner-title.png" alt="" srcset="" v-else>
          <ul class="advantage-list flex">
            <li v-for="(adv, index) of advantageList" :key="index" class="flex flex-column">
              <span>{{ adv.title }}</span>
              <span class="sub-text">{{ adv.subText }}</span>
            </li>
          </ul>
          <div class="flex btn-group">
            <div class="btn active"  :class="{'btn-locale': $i18n.locale == 'en'}" @click="$open('signUp', $i18n.locale)">
              <span class="icon"></span>
              <span>{{$t('home.slider.common.openRealAccount')}}</span>
            </div>
            <div class="btn" :class="{'btn-locale': $i18n.locale == 'en'}" @click="$open('signUp5', $i18n.locale)">
              <span class="icon"></span>
              <span>{{$t('home.slider.common.openRealAccount5')}}</span>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item key="3">
          <h4 :class="{'h4-locale': $i18n.locale == 'en'}">{{$t("home.slider.banner3.describe1")}}</h4>
          <p>{{$t("home.slider.banner3.describe2")}} </p>
          <div class="flex btn-group flex__justify-center">
            <div class="btn active"  :class="{'btn-locale': $i18n.locale == 'en'}" @click="$open('signUp', $i18n.locale)">
              <span class="icon"></span>
              <span>{{$t('home.slider.common.openRealAccount')}}</span>
            </div>
            <div class="btn" :class="{'btn-locale': $i18n.locale == 'en'}" @click="$open('signUp5', $i18n.locale)">
              <span class="icon"></span>
              <span>{{$t('home.slider.common.openRealAccount5')}}</span>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import HomeHeader from '@/components/header/header';

export default {
  name: 'slider',

  components: {
    HomeHeader
  },

  data() {
    return {
      activeIndex: 0,
    }
  },
  computed: {
    advantageList() {
      return [
        {
          title: this.$t("home.slider.banner2.tabTitle1"),
          subText: this.$t("home.slider.banner2.tabSubText1"),
        },
        {
          title: this.$t("home.slider.banner2.tabTitle2"),
          subText: this.$t("home.slider.banner2.tabSubText2"),
        },
        {
          title: this.$t("home.slider.banner2.tabTitle3"),
          subText: this.$t("home.slider.banner2.tabSubText3"),
        }
      ];
    },
    wrapperClass() {
      let cl = this.$i18n.locale == 'en' ? `home-slider-${this.activeIndex}-en home-slider-${this.activeIndex}` : `home-slider-${this.activeIndex}`
      return cl
    }
  },

  methods: {
    changeWrapperClassName(index) {
      this.activeIndex = index;
    }
  }
}
</script>

<style scoped lang="scss">
.home-slider {
  height: 720px;
  background-size: cover;
  background-position: center center;
  @include bg-image('../../assets/image/home/slider1');
  &-1 {
    @include bg-image('../../assets/image/home/slider2');
  }
  &-2 {
    @include bg-image('../../assets/image/home/slider3');
    text-align: center;
    h4 {
      height: 60px;
      font-size: 44px;
      font-family: AlibabaPuHuiTi-Bold, AlibabaPuHuiTi;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 60px;
      text-shadow: 0px 3px 10px rgba(0,0,0,0.5000);
    }
    .h4-locale{
      height: 120px;
    }
    p {
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC;
      color: rgba(255, 255, 255, 0.9);
      line-height: 25px;
      text-shadow: 0px 2px 4px rgba(0,0,0,0.3000);
      margin-top: 13px;
      margin-bottom: 164px;
    }
  }
  &-0-en{
    @include bg-image('../../assets/image/home/slider1-en');
  }

  .el-carousel__item {
    padding: 139px 128px;
    .title {
      font-size: 50px;
    }
    .advantage-list {
      margin-top: 60px;
      li {
        margin-right: 60px;
        &::before {
          content: '';
          display: inline-block;
          width: 24px;
          height: 6px;
          background: #FFFFFF;
          border-radius: 4px;
        }
        span {
          margin-top: 10px;
          font-size: 16px;
          &.sub-text {
            font-size: 14px;
            font-weight: 400;
            margin-top: 6px;
            padding: 3px 0;
            color: rgba(255, 255, 255, .6);
          }
        }
      }
    }
    .btn-group {
      margin-top: 100px;
      .btn {
        font-size: 16px;
        color: #fff;
        border: 1px solid #fff;
        font-weight: normal;
        width: 200px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        transition: color 0.3s;
          margin-left: 12px;
        .icon {
          display: inline-block;
          vertical-align: middle;
          margin-right: 8px;
          width: 16px;
          height: 16px;
          background-image: url(../../assets/image/home/icon-person.png);
        }
        span {
          height: 22px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 22px;
        }

        &:first-child {
          margin-left: 0;
        }
      }
      .btn-locale{
        width: 300px;
      }
      .active {
        background-color: #fff;
        color: #0D73D6;
        .icon {
          background-image: url(../../assets/image/home/icon-person-active.png);
        }
        span {
          color: #0D73D6;
        }
      }
    }
  }
  /deep/ .el-carousel__indicator--horizontal {
    padding: 12px 7px;
    .el-carousel__button {
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
  }
}

  @media screen and (min-width: 992px) and(max-width: 1200px) {
    .home-slider{
      /*width: 1200px;*/
    }
  }

  @media screen and (min-width: 768px) and(max-width: 992px) {

  }
  @media screen and (max-width: 768px) {

  }
</style>
