<template>
  <div class="fund-table">
    <div class="warpper">
      <div class="tabs">
        <div :class="['tab', curActive === item.num ? 'active' : '']" v-for="item in tabs" :key="item.num" @click="clickMe(item.num)">{{item.name}}<div class="line"></div></div>
      </div>
      <div class="table-box">
        <el-table :data="money">
          <el-table-column
            prop="src"
            align="center"
            label="">
            <template slot-scope="scope">
              <img :src="scope.row.src">
            </template>
          </el-table-column>
          <el-table-column
            prop="time"
            align="center"
            :label="$t('support.fundTable.time')">
          </el-table-column>
          <el-table-column
            prop="num"
            align="center"
            :label="$t('support.fundTable.gold')">
          </el-table-column>
          <el-table-column
            prop="type"
            align="center"
            :label="$t('support.fundTable.currency')">
          </el-table-column>
          <el-table-column
            prop="money"
            align="center"
            :label="$t('support.fundTable.cost')">
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="warpper fund-step" v-if="curActive === 1">
      <h3>{{$t("support.fundInfo.goldIn")}}</h3>
      <h4>{{$t("support.fundInfo.step1")}}</h4>
      <h4>{{$t("support.fundInfo.step2")}}</h4>
      <h4>{{$t("support.fundInfo.step3")}}</h4>
      <h4>{{$t("support.fundInfo.step4")}}</h4>
      <h4>{{$t("support.fundInfo.step5")}}</h4>
      <img v-if="$i18n.locale == 'en'" src="@/assets/image/support/fund-step5-en.png">
      <img v-else src="@/assets/image/support/fund-step5.png">
      <h4>{{$t("support.fundInfo.step6")}}</h4>
      <h4>{{$t("support.fundInfo.step7")}}</h4>
      <img v-if="$i18n.locale == 'en'" src="@/assets/image/support/fund-step7-en.png">
      <img v-else src="@/assets/image/support/fund-step7.png">
      <h4>{{$t("support.fundInfo.step8")}}(<span>{{$t("support.fundInfo.step8-1")}}</span>)</h4>
      <img v-if="$i18n.locale == 'en'" src="@/assets/image/support/fund-step8-en.png">
      <img v-else src="@/assets/image/support/fund-step8.png">
      <h4>{{$t("support.fundInfo.step9")}}(<span>{{$t("support.fundInfo.step9-1")}}</span>)</h4>
    </div>
    <div class="warpper fund-step" v-if="curActive === 2">
      <h3>{{$t("support.fundInfo.goldOut")}}</h3>
      <h4>{{$t("support.fundInfo.out-step1")}}</h4>
      <img v-if="$i18n.locale == 'en'" src="@/assets/image/support/out-step1-en.png">
      <img v-else src="@/assets/image/support/out-step1.png">
      <h4>{{$t("support.fundInfo.out-step2")}}</h4>
      <img v-if="$i18n.locale == 'en'" src="@/assets/image/support/out-step2-en.png">
      <img v-else src="@/assets/image/support/out-step2.png">
      <img v-if="$i18n.locale == 'zh'" src="@/assets/image/support/out-step2-1.png">
      <h4>{{$t("support.fundInfo.out-step3")}}</h4>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      curActive: 1,
    }
  },
  computed: {
    tabs() {
      return [
        {
          name: this.$t('support.fundTable.golden'),
          num: 1
        }, {
          name: this.$t('support.fundTable.withdrawals'),
          num: 2
        }
      ]
    },
    money() {
      return [
        {
          src: require('@/assets/image/support/demo.png'),
          time: this.$t('support.fundTable.workday1'),
          num: '50-1000000',
          type: 'USDT TRC20',
          money: `${this.$t('support.fundTable.money')}`
        },
        // {
        //   src: require('@/assets/image/support/demo.png'),
        //   time: this.$t('support.fundTable.workday2'),
        //   num: '$200',
        //   type: 'USD，EUR，GBP',
        //   money: '$0'
        // }, {
        //   src: require('@/assets/image/support/demo.png'),
        //   time: this.$t('support.fundTable.workday3'),
        //   num: '$300',
        //   type: 'USD，EUR，GBP',
        //   money: '$0 '
        // }, {
        //   src: require('@/assets/image/support/demo.png'),
        //   time: this.$t('support.fundTable.workday4'),
        //   num: '$400',
        //   type: 'USD，EUR，GBP',
        //   money: '$0'
        // }
      ]
    }
  },
  methods:{
    clickMe(num) {
      this.curActive = num
    }
  }
}
</script>
<style scoped lang="scss">
/deep/ .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}
/deep/ .el-table--mini .el-table__cell {
    padding: 14px 0;
}
.fund-table{
  background: #F5F6F8;
  padding-top: 80px;
  padding-bottom: 100px;
  .warpper{
    width: 1120px;
    margin: 0 auto;
  }
  .tabs{
    display: flex;
    justify-content: center;
    .tab{
      font-size: 36px;
      cursor: pointer;
      color: rgba($color: #000000, $alpha: 0.6);
      line-height: 50px;
      margin: 0 18px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .line{
        width: 48px;
        height: 6px;
        background: #000000;
        border-radius: 3px;
        display: none;
      }
      &.active{
        font-size: 36px;
        color: #000000;
        font-weight: bold;
        line-height: 50px;
        .line{
          display: block;
        }
      }
    }
  }
  .table-box{
    box-shadow: 0px 0px 20px 0px rgba(33,66,171,0.1500);
    border-radius: 2px;
    margin-top: 40px;
  }
  .fund-step {
    h3{
      font-size: 28px;
      font-weight: bold;
      font-weight: 500;
      color: #333333;
      line-height: 33px;
      margin-top: 60px;
      margin-bottom: 20px;
    }
    h4{
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
      margin: 20px 0;
      span {
        color: #FF0000;
      }
    }
    img{
      width: 100%;
      margin-bottom: 20px;
    }
  }
}

@media screen and (min-width: 1200px) {

}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .fund-table{
    .warpper{
      width: 95vw;

    }
  }

}

@media screen and (max-width: 992px) {
  .fund-table{
    padding-top: 20px;
    .warpper{
      width: 95vw;

    }
    .tabs{
      .tab{
        font-size: 18px;
        .line{
          width: 24px;
          height: 3px;
        }
        &.active{
          font-size: 18px;
        }
      }
    }
  }
}
</style>